import { createSlice } from "@reduxjs/toolkit";

const initialState={
    user:null,
    city:null
}

const userSlice=createSlice({
    name:'userData',
    initialState,
    reducers:{
        setUser: (state, action) => {
            state.user=action.payload
        },
        clearUser:(state)=>{
            state.user=null
        },
        setCity:(state,action)=>{
            state.city=action.payload
        }
    }
})
export const { setUser ,clearUser,setCity} = userSlice.actions;

export default userSlice.reducer;