import "../../global.css";
import img1 from "../../assets/images/aboutUsImg1.jpeg";
import img2 from "../../assets/images/aboutusimg2.jpeg";
import styles from "./styles.module.css";
import AboutUsCard from "../../components/aboutus card";

const Aboutus = () => {
  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <div className={styles.AllContentDiv}>
        <h1 className="heading">About us</h1>

        <div className={styles.aboutusContent}>
          <img src={img1} alt="image" className={styles.imageOfAbout} />

          <div>
            <h2 className={styles.headingOfContent}>Who We Are</h2>
            <br></br>
            <p className={styles.textOfAbout}>
              Welcome to Wildcard Tickets, where the excitement begins and the
              possibilities are endless! We are your go-to destination for
              seamless event experiences, connecting you to the hottest
              concerts, sports games, theater performances, and more. At
              Wildcard Tickets, we believe that every event is a chance to
              create unforgettable memories, and we're here to make sure you
              don't miss a moment.
            </p>
          </div>
        </div>

        <div className={styles.aboutusContentFlip}>
          <div>
            <h2 className={styles.headingOfContent}>Our Mission</h2>
            <br></br>
            <p className={styles.textOfAbout}>
              At Wildcard Tickets, our mission is simple: to provide you with
              access to the events that matter most to you. We strive to be the
              bridge between you and the most sought-after experiences, offering
              a diverse range of tickets to cater to every taste and preference.
              Whether you're a sports fanatic, a music lover, or a theater
              enthusiast, we've got your ticket to excitement.
            </p>
          </div>
          <img src={img2} alt="image" className={styles.imageOfAbout} />
        </div>
        <br></br>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className={styles.headingOfRed}>Why choose WildCard Tickets</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
          <AboutUsCard
            title="Wide Selection"
            content="We curate a comprehensive selection of tickets to a variety of events, ensuring that there's something for everyone. From blockbuster concerts to nail-biting sports matchups, we've got the ticket you're looking for."
          />

          <AboutUsCard
            title="User-Friendly Platform"
            content="Our user-friendly website and mobile app are designed to make your ticket-buying experience smooth and enjoyable. Search, select, and purchase tickets with ease, all from the comfort of your own device."
          />

          <AboutUsCard
            title="Secure Transactions"
            content="Your security is our priority. We utilize the latest technology to ensure that your transactions are safe and your personal information is protected."
          />

          <AboutUsCard
            title="Customer Service Excellence"
            content="Have a question or need assistance? Our dedicated customer service team is here to help. We pride ourselves on providing top-notch customer service to enhance your overall experience with Wildcard Tickets."
          />

          <AboutUsCard
            title="Customer Service Excellence"
            content="Be on the lookout for exclusive deals, promotions, and special offers. We believe in giving our customers added value and opportunities to save on their favorite events."
          />
        </div>

        <br></br>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className={styles.headingOfRed}>Join the Wildcard Community</p>
        </div>

        

        <div className={styles.ContentOfJoinUs}>
          <p className={styles.AboutUsJoinContent}>By choosing Wildcard Tickets, you're not just buying a ticket; you're joining a community of passionate event-goers who share your enthusiasm for live experiences. Follow us on social media to stay in the loop about the latest events, promotions, and behind-the-scenes glimpses into the world of Wildcard Tickets.</p>
          <br></br>
          <p className={styles.AboutUsJoinContent}>Thank you for choosing Wildcard Tickets for your ticketing needs. We look forward to being your trusted partner in creating unforgettable memories at the events you love.</p>
        </div>
        
        <br></br>

      </div>
    </div>
  );
};

export default Aboutus;
