import {Route,Routes} from 'react-router-dom'
import Home from '../containers/Home/index'
import Details from '../containers/Details'
import Events from '../containers/events'
import Categorydetail from '../containers/categoryDetail'
import BookTicket from '../containers/bookticket'
import NotFound from '../containers/Notfound'
import ScrollToTop from '../components/scroll top'
import Contactus from '../containers/contactus'
import Userprofile from '../containers/profileuser'
import PrivacyPolicy from '../containers/privacypolicy'
import Tandc from '../containers/terms&contd'
import Gallery from '../components/gallery'
import Aboutus from '../containers/aboutus'
import ListYourevents from '../containers/lisyyourevents'
import Search from '../containers/search'
import Viewmore from '../containers/viewmore'
import AccountMenu from '../components/account menu'
import PaymentSuccess from '../containers/paymentverification'
import ConfirmationSection from '../containers/confirm'
import GenerateTicket from '../containers/generateticket'
import Career from '../containers/career'

import Bookings from '../containers/bookings'
const Rout=()=>{
    return(
        <>
            <ScrollToTop />
        <Routes>
            <Route path='/' element={<Home />}/>
            <Route path='/events' element={<Events/>}/>
            <Route path='/details/:eventId' element={<Details/>}/>
            <Route path='/viewmore' element={<Viewmore/>} />
            <Route path='/:category' element={<Categorydetail />} />
            <Route path='/bookticket' element={<BookTicket />} />
            <Route path='/contactus' element={<Contactus/>} />
            {/* <Route path='/myprofile' element={<Userprofile/>}/> */}
            {/* <Route path='accountmenu/mybookings' element={<Bookings/>} /> */}
            <Route path='/privacypolicy' element={<PrivacyPolicy />}/>
            <Route path='/termsandcondition' element={<Tandc/>} />
            <Route path='/gallery' element={<Gallery />}/>
            <Route path='/aboutus'  element={<Aboutus/>}/>
            <Route path='/listyourevents'  element={<ListYourevents/>}/>
            <Route path='/search' element={<Search />}/>
            <Route path='/accountmenu' element={<AccountMenu/>}/>
            <Route path='/bookingsUser' element={<Bookings/>}/>
            <Route path='/paymentsuccess' element={<PaymentSuccess/>}/>
            <Route path='/confirmed'  element={<BookTicket/>}/>
            <Route path='/generateticket'  element={<GenerateTicket/>}/>
            <Route path='/career'  element={<Career/>}/>
            
            <Route path='*' element={<NotFound />}/>
        </Routes>
        </>
    )
}

export default Rout