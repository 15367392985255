import "../../global.css";
import React from "react";
import LoginPopup from "../../containers/login";
import {

  UserOutlined,

  LoadingOutlined 
} from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { Dropdown, Menu, Avatar, Button, Modal, Divider, Spin } from "antd";
import Sidebar from "../Sidebar";
import { useNavigate, useLocation, Link } from "react-router-dom";
import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/images/logo.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { clearUser, setCity } from "../../slice";
import SearchModal from "../searchModal";
import Ranchi from "../../assets/images/ranchi.png";
import { googleLogout, getcities ,citySearch} from "../../requests";


const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [showMoreCities, setShowMoreCities] = useState(false);
  const [moreCities, setmorecities] = useState([]);
  const modalRef = useRef(null);
  const storedLocation = localStorage.getItem("selectedLocation");
  const cleanedLocation = storedLocation?.replace(/"/g, "");

  const [detectLoading, setDetectLoading] = useState(false);

  const [detectlocation, setdetectLocation] = useState(null);
  const [error, setError] = useState(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

const [loading, setLoading] = useState(false);




const handleSearch = async () => {
  setLoading(true); 
  try {
    if (searchQuery.trim() !== '') {
      const resp = await citySearch(searchQuery);
      // console.log(resp.data)
      setSuggestions(resp.data)
    } else {
      setSuggestions([]);
    }
  } catch (err) {
    console.log(err)
  }finally{
    setLoading(false);
  }
}


useEffect(() => {
  
  handleSearch();
}, [searchQuery]);

  const city = useSelector((state) => state?.userData?.city);

  const navigate = useNavigate();

  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const user = useSelector((state) => state.userData.user);

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };

  const showModal = () => {
    setIsModalVisible(true);
    modalRef.current?.classList.add("ant-scrolling");
    // document.body.style.overflow = 'hidden';
  };

  const handleCancel = () => {
    if (selectedCity) {
      setIsModalVisible(false);
      setSuggestions([])
      setSearchQuery("")
    } else {
      console.log("Please select a location before closing the modal");
    }
  };
  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };
  useEffect(() => {
    const storedLocation = localStorage.getItem("selectedLocation");
    if (storedLocation) {
      setSelectedCity(JSON.parse(storedLocation));
    } else {
      setIsModalVisible(true);
    }
  }, []);

  const handleCitySelect = (cityName) => {
    setSelectedCity(cityName);
    localStorage.setItem("selectedLocation", JSON.stringify(cityName));
    dispatch(setCity(cityName));
    setIsModalVisible(false);
    setSearchQuery("")
    setSuggestions([])
  };

  useEffect(() => {
    const header = document.getElementById("header");

    const handleScroll = () => {
      const isScrolled = window.scrollY >= 100;
      const isNotRootPath = location.pathname !== "/";
      const isNotDetailsPath = !location.pathname.startsWith("/details");

      if (isScrolled || (isNotRootPath && isNotDetailsPath)) {
        header.classList.add(styles.opaque);
      } else {
        header.classList.remove(styles.opaque);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  const handleLogout = () => {
    gout();
    dispatch(clearUser());
    navigate("/");
    // localStorage.clear();
  };

  const gout = async () => {
    try {
      const resp = await googleLogout();
      console.log(resp);
    } catch (err) {
      console.log(err);
    }
  };

  const menu = (
    <Menu style={{width:'120px',padding:'10px'}}> 

    <Menu.Item><p className="text-lg font-bold text-gray-500">Welcome!</p></Menu.Item>
    
      <Link to="/accountmenu" >
        <Menu.Item style={{backgroundColor:'white'}} key="1">Profile</Menu.Item>
      </Link>

      <Link to="/bookingsUser" >
        <Menu.Item style={{backgroundColor:'white'}} key="1">Bookings</Menu.Item>
      </Link>

      <Menu.Item key="3" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const isActive = (path) => location.pathname === path;

  const handleDetectLocation = async () => {
    if ("geolocation" in navigator) {
      try {
        setDetectLoading(true);
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;

        const response = await fetch(
          `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=0a9d566d7bf24d3596491092c3f95995`
        );

        if (!response.ok) {
          throw new Error("Error fetching location details");
        }

        const data = await response.json();

        const stateDistrict =
          data.results &&
          data.results[0] &&
          data.results[0].components &&
          data.results[0].components.state_district;

        const cityName = stateDistrict && stateDistrict.split(" ")[0];

        setdetectLocation({ latitude, longitude, cityName });
        dispatch(setCity(cityName));
        setIsModalVisible(false);
        // console.log(cityName, "=====================");
      } catch (err) {
        setError(`Error getting location: ${err.message}`);
      } finally {
        setDetectLoading(false);
      }
    } else {
      setError("Geolocation is not supported by your browser");
    }
  };

  const handleToggleMoreCities = () => {
    // Toggle the state to show/hide more cities
    setShowMoreCities((prevShowMoreCities) => !prevShowMoreCities);
  };

  const citiesGet = async () => {
    try {
      const resp = await getcities();
      setmorecities(resp.data);
      // console.log(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    citiesGet();
  }, []);

  return (
    <header
      // style={{maxWidth:'1300px'}}
      id="header"
      className=" bg-black bg-opacity-25 text-white p-4 flex justify-between items-center fixed top-0 w-full z-50 colChange "
    >
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />

      <div className="flex items-center gap-3 ">
        <div style={{ marginRight: "90px" }}>
          <Link to="/">
            <img
              src={logo}
              alt="wildcard"
              style={{ width: "185px", height: "46px" }}
            />
          </Link>
          {/* <div className="h-8 w-20">Wild Ca</div> */}
        </div>
        <nav className="hidden md:flex space-x-4 gap-4">
          <Link
            to="/"
            className={`hover:text-gray-400 ${
              isActive("/") ? "text-[#FF275E]" : ""
            }`}
            style={{ fontSize: "17px", fontWeight: "500" }}
          >
            Home
          </Link>

          <Link
            to="/events"
            className={`hover:text-gray-400 ${
              isActive("/events") ? "text-[#FF275E]" : ""
            }`}
            style={{ fontSize: "17px", fontWeight: "500" }}
          >
            Events
          </Link>

          <Link
            to="/aboutus"
            className={`hover:text-gray-400 ${
              isActive("/aboutus") ? "text-[#FF275E]" : ""
            }`}
            style={{ fontSize: "17px", fontWeight: "500" }}
          >
            About us
          </Link>

          {/* <Link
            to="/contactus"
            className={`hover:text-gray-400 ${
              isActive("/contactus") ? "text-[#FF275E]" : ""
            }`}
            style={{ fontSize: "17px", fontWeight: "500" }}
          >
            Contact Us
          </Link> */}

          <Link
            to="/listyourevents"
            className={`hover:text-gray-400 ${
              isActive("/listyourevents") ? "text-[#FF275E]" : ""
            }`}
            style={{ fontSize: "17px", fontWeight: "500" }}
          >
            List Your Events
          </Link>
        </nav>
      </div>

      <div className="flex items-center space-x-4 ">
        <button onClick={openSearchModal}>
          <FontAwesomeIcon icon="fa-magnifying-glass" />
        </button>
        {/* <Dropdown overlay={city} placement="bottomRight" arrow> */}
        <Button
          style={{
            color: "white",
            transition: "color 0.3s",
            borderRadius: "50px",
          }}
          onMouseOver={(e) => (e.currentTarget.style.color = "red")}
          onMouseOut={(e) => (e.currentTarget.style.color = "white")}
          onClick={showModal}
        >
          <span style={{ display: "flex", gap: "5px" }}>
            {city || "Select City"}
            <FontAwesomeIcon icon="fa-sort-down" />
          </span>
        </Button>
        {/* </Dropdown> */}
        {!user && (
          <Button
            onClick={openLoginModal}
            danger
            type="primary"
            className={` ${styles.mobile}`}
            style={{ backgroundColor: "#FF275E" }}
          >
            Sign In
          </Button>
        )}
        {user ? (
         <Dropdown overlay={menu} placement="bottomRight" arrow className={` ${styles.mobile}`}> 
         <Button style={{ border: 'none',marginBottom:'10px'}} >
           <Avatar icon={<UserOutlined />} />
         </Button>
       </Dropdown>
        ) : null}
        <div>
          {" "}
          <button onClick={toggleSidebar} className={styles.openSidebarButton}>
            <FontAwesomeIcon icon="fa-bars" />
          </button>
        </div>
      </div>
      <LoginPopup isOpen={isLoginModalOpen} onClose={closeLoginModal} />

      <Modal
        title="Select City"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        ref={modalRef}
        closable={false}
        // centered
        // width={1000}
        className={styles.modal}
      >
        <div className="pt-2">
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              style={{ color: "#ff275e", fontSize: "15px" }}
              onClick={handleDetectLocation}
            >
              <FontAwesomeIcon icon="fa-location-crosshairs" /> Detect My
              Location
            </button>

            <Spin spinning={detectLoading} />
          </div>

          <div className="relative"> 
        <div className="flex items-center bg-white rounded-md overflow-hidden mt-4" >
      
          <input
            type="text"
            placeholder="Search for City"
            className="w-full py-2 px-4 border-none focus:outline-none bg-gray-200 "
            style={{ color: 'black' }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          
          {/* <button className="text-white px-4 py-2" style={{ background: '#ff275e' }}><FontAwesomeIcon icon="fa-magnifying-glass" /></button> */}
        </div>

       
      {searchQuery.trim() !== '' && <div className={styles.searchres}>
          {loading && (
            <div className="flex justify-center mt-4">
              <Spin 
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
          /> 
            </div>
          )}
          <ul>
          {!loading && suggestions.length === 0 && (
                <li className={styles.noresult}>No results found</li>
              )}
            {!loading && suggestions.map((suggestion, index) => (
              <li key={suggestion._id}  onClick={() => handleCitySelect(suggestion.name)} className={styles.list}>
                {suggestion.name}
              </li>
            ))}
          </ul>
          
        </div>
}
</div>

          <br></br>
          <hr></hr>
          <br></br>
          <div className={styles.cities}>
            <div
              className={styles.city}
              onClick={() => handleCitySelect("Mumbai")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/mumbai.png"
                className={styles.cityImage}
              />
              <p className={styles.cityFont}>Mumbai</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Delhi")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/ncr.png"
                className={styles.cityImage}
              />
              <p>Delhi</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Hyderabad")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/hyd.png"
                className={styles.cityImage}
              />
              <p>Hyderabad</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Bengaluru")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/bang.png"
                className={styles.cityImage}
              />
              <p>Bengaluru</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Ahmedabad")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/ahd.png"
                className={styles.cityImage}
              />
              <p>Ahmedabad</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Chandigarh")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/chd.png"
                className={styles.cityImage}
              />
              <p>Chandigarh</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Ranchi")}
            >
              <img alt="delhi" src={Ranchi} className={styles.cityImage} />
              <p>Ranchi</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Pune")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/pune.png"
                className={styles.cityImage}
              />
              <p>Pune</p>
            </div>

            <div
              className={styles.city}
              onClick={() => handleCitySelect("Kolkata")}
            >
              <img
                alt="delhi"
                src="https://in.bmscdn.com/m6/images/common-modules/regions/kolk.png"
                className={styles.cityImage}
              />
              <p>Kolkata</p>
            </div>
          </div>
          <br></br>
          <br></br>
          {showMoreCities && (
            <div className="overflow-y-scroll max-h-40">
              <div
                className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4"
                style={{ marginLeft: "15px" }}
              >
                {moreCities.map((city) => (
                  <div
                    key={city.id}
                    className={styles.city}
                    onClick={() => handleCitySelect(city.name)}
                  >
                    <p style={{ color: "#7b7b7b" }}>{city.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="link"
              style={{ color: "#ff275e" }}
              onClick={handleToggleMoreCities}
            >
              View More cities
            </Button>
          </div>
        </div>
      </Modal>
      <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />
    </header>
  );
};

export default Header;
