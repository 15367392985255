import "../../global.css";
import './ant.css'
import React, { useEffect, useState } from "react";
import { UserOutlined, LoadingOutlined }  from '@ant-design/icons';
import styles from "./styles.module.css";
import { Form, Input, Button, Divider, notification, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  addDetails,
  getAlldetails,
  googleUserDetailsUpdate,
} from "../../requests";
import {Avatar} from "antd";
import { setUser } from "../../slice";

const Userprofile = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState("");
  const [loading,setLoading]=useState(false)

  const currentUser = useSelector((state) => state?.userData?.user?.user);
  const googleUser = useSelector((state) => state?.userData?.user);

  const ph = currentUser?.phoneNumber;

  const emailAdd = googleUser?.email;

  const gPhone=googleUser?.phoneNumber

  const gid = googleUser?.userID;

  const det = details?.lastName;

  // console.log(gid);

  const handleSavePersonalDetails = async (values) => {
    try {
      setLoading(true)
      console.log("Saved personal details:", values);
      if (emailAdd) {
        const resp = await googleUserDetailsUpdate(values, gid);
        // const updatedData=resp.data.data
        // dispatch(setUser(updatedData))
      } else {
        const resp = await addDetails(values, ph);
      }
      // dispatch(setUser(resp))
      notification.success({
        message: "Details Updated",
        description: "Personal details have been updated successfully.",
      });
      setTimeout(() => {
        detailsOfUser();
      }, 2000);

      // console.log(resp)
    } catch (err) {
      notification.error({
        message: "Error",
        description: "An error occurred while updating details.",
      });
      console.log(err);
    }finally{
      setLoading(false)

    }
  };

  const handleSaveAddress = async (values) => {
    try {
      setLoading(true)
      if (emailAdd) {
        const resp = await googleUserDetailsUpdate(values, gid);
        
      } else {
        const resp = await addDetails(values, ph);
      }
      notification.success({
        message: "Details Updated",
        description: "Address have been updated successfully.",
      });
      setTimeout(() => {
        detailsOfUser();
      }, 2000);
    } catch (err) {
      notification.error({
        message: "Error",
        description: "An error occurred while updating details.",
      });
      console.log(err);
    }finally{
      setLoading(false)
    }
  };

  const detailsOfUser = async () => {
    try {
      const resp = await getAlldetails({ phoneNumber: ph, email: emailAdd });
      // console.log(resp.data.data,'+++++++++++++++++++++++++')
      setDetails(resp.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    detailsOfUser();
  }, []);

  console.log(details, "-----------");

  return (
    <div
      className="container"
      style={{ display: "flex", justifyContent: "center", background: "#f6f6f6" }}
    >
       <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} spinning={loading} fullscreen/>
      <div className={styles.maindiv}>
        {/* <div style={{ marginBottom: "20px" }} className={styles.section}>
          <h2 className={styles.heading}>Account Details</h2>
          <Divider />
          {details.phoneNumber && <p>Mobile Number: {details.phoneNumber}</p>}
          {details.email && <p>Email: {details.email}</p>}
        </div> */}

        {/* <Divider /> */}

        <div style={{ marginBottom: "20px" }} className={styles.section}>
          <div className={styles.AvatarSection}>
          <Avatar size={150} icon={<UserOutlined />} />
          <div>
          <h2 className={styles.ProfileHeadings}>Personal Information</h2>
          
          <br></br>
          {details && (
            <Form
              name="personalDetailsForm"
              layout="vertical"
              initialValues={{
                firstName: details.firstName,
                lastName: details.lastName,
                email:details.email,
                phoneNumber:emailAdd ?details?.phoneNumber:ph
              }}
              onFinish={handleSavePersonalDetails}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    { required: true, message: "Please enter your first name" },
                  ]}
                  
                >
                  <Input disabled={emailAdd !== undefined} />
                </Form.Item>

                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    { required: true, message: "Please enter your last name" },
                  ]}
                >
                  <Input disabled={emailAdd !== undefined} />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  
                >
                  <Input  disabled={emailAdd !== undefined}/>
                </Form.Item>

                <Form.Item
                  name="phoneNumber"
                  label="Phone"
                  // initialValue={}
                  >
                  
                  <Input disabled={ph !== undefined} /> 
                </Form.Item>
              </div>

              
                <Form.Item>
                  <button className={styles.btn} htmlType="submit">
                    Save
                  </button>
                </Form.Item>
              
            </Form>
          )}
        </div>
        </div>
        </div>
        

        <div className={styles.section}>
        <div className={styles.AvatarSection}>
          <div style={{visibility:'hidden'}}>
          <Avatar size={100} icon={<UserOutlined />} />
          </div>
          <div>
          <h2 className={styles.ProfileHeadings}>Address</h2>
          <br></br>
          {details && (
            <Form
              name="addressForm"
              initialValues={{
                addressLine1: details.addressLine1,
                addressLine2: details.addressLine2,
                pincode: details.pincode,
                state: details.state,
              }}
              onFinish={handleSaveAddress}
              layout="vertical"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6">
              <Form.Item
                name="addressLine1"
                label="Address Line 1"
                rules={[
                  { required: true, message: "Please enter address line 1" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="addressLine2" label="Address Line 2">
                <Input />
              </Form.Item>

              <Form.Item
                name="pincode"
                label="Pincode"
                rules={[{ required: true, message: "Please enter pincode" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="state"
                label="State"
                rules={[{ required: true, message: "Please enter state" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <button className={styles.btn} htmlType="submit">
                  Save
                </button>
              </Form.Item>
              </div>
            </Form>
          )}
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Userprofile;
