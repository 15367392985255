import Img from "../../assets/images/cov.jpg";
import styles from "../bookticket/styles.module.css";
import seat from "../../assets/images/s-1.jpeg";
import {
  Card,
  Divider,
  Button,
  notification,
  Modal,
  message,
  Input,
} from "antd";
import { setSeatPrices } from "../../seatPriceslice";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  googleUserDetailsUpdate,
  addDetails,
  getAlldetails,
} from "../../requests";
import "./tic.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const TicketSelectionSection = ({ onNext, ticketDetails }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedSeats, setSelectedSeats] = useState([]);
  const currentUser = useSelector((state) => state?.userData?.user?.user);
  const googleUser = useSelector((state) => state?.userData?.user);
  const isLoggedIn = useSelector((state) => state?.userData?.user?.user?._id);
  const gLoggedIn = useSelector((state) => state?.userData?.user?.userID);

  const phone = useSelector(
    (state) => state?.userData?.user?.user?.phoneNumber
  );
  const checkPhoneNumber = useSelector(
    (state) => state?.userData?.user?.phoneNumber
  );

  const gid = googleUser?.userID;
  const ph = currentUser?.phoneNumber;
  const emailAdd = googleUser?.email;

  const [modalvisibleEmail, setIsModalVisibleEmail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [DetailsOfuser, setDetailsofUser] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");

  dispatch(setSeatPrices(ticketDetails?.seatPrices));

  const eventDateObject = new Date(ticketDetails?.eventDate);
  const formattedEventDate = eventDateObject.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const handleModalOk = async () => {
    try {
      await phoneNumberValidator(null, phoneNumberInput);
      const resp = await googleUserDetailsUpdate(
        { phoneNumber: phoneNumberInput },
        gid
      );
      // console.log(resp);

      if (resp.status === 200) {
        handleModalCancel();
        message.success("Phone number updated successfully");
      } else {
        console.log("Unexpected response status:", resp.status);
        message.error("Please enter phone number to proceed.");
      }
    } catch (err) {
      console.log(err);
      message.error("Please enter phone number to proceed.");
    }
  };
  const phoneNumberValidator = (_, value) => {
    const isNumeric = /^[0-9]*$/; // Regex to check if the value is numeric

    if (!value) {
      return Promise.reject("Please enter your phone number.");
    } else if (!isNumeric.test(value)) {
      return Promise.reject("Please enter a valid numeric phone number.");
    } else if (value.length !== 10) {
      return Promise.reject("Phone number must be 10 digits long.");
    }

    return Promise.resolve();
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsModalVisibleEmail(false);
  };

  const handleModalOkEmail = async () => {
    try {
      if (!nameInput) {
        message.error("Please Enter Your Name to Proceed.");
        return;
      }
      const resp = await addDetails(
        { firstName: nameInput, email: emailInput },
        phone
      );
      // console.log(resp);
      if (resp.status === 200) {
        message.success("Details updated successfully");
        handleModalCancel();
      } else {
        console.log("Unexpected response status:", resp.status);
        message.error("Please enter Details to proceed.");
      }
    } catch (err) {
      console.log(err);
      message.error("Please Enter Email to proceed.");
    }
  };

  const detailsOfUser = async () => {
    try {
      const resp = await getAlldetails({ phoneNumber: phone, email: emailAdd });
      console.log(resp.data.data, "+++++++++++++++++++++++++");
   
      const test = resp.data.data;
      const shouldShowModal = isLoggedIn && !test.email && !test.firstName;
      const shouldPhonemodal=gLoggedIn && !test.phoneNumber
      setIsModalVisibleEmail(shouldShowModal);
      setIsModalVisible(shouldPhonemodal);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const storedSeats = localStorage.getItem("selectedSeats");
    if (storedSeats) {
      setSelectedSeats(JSON.parse(storedSeats));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("selectedSeats", JSON.stringify(selectedSeats));
  }, [selectedSeats]);

//   const handleConfirmAndProceed = async () => {
//     await detailsOfUser();
// const ticketId = localStorage.getItem("ticketId");

//     if (!ticketId) {
//       notification.error({
//         message: "Something went wrong. Please try again later.",
//         duration: 3,
//       });

//       navigate("/");
//       return;
//     }

//     if (calculateTotalPriceNew() > 0) {
//       const selectedLabels = [
//         ...new Set(selectedSeats.map((seat) => seat.label)),
//       ];

//       const finalSeats = selectedLabels.flatMap((label) =>
//         selectedSeats.filter((seat) => seat.label === label)
//       );

//       localStorage.setItem("finalseats", JSON.stringify(finalSeats));
//       checkouthandler(calculateTotalPriceNew());
//     } else {
//       notification.warning({
//         message: "Select Seat to proceed further",
//         duration: 3,
//       });
//     }
//   };


const handleConfirmAndProceed = async () => {
  try {
    const resp = await getAlldetails({ phoneNumber: phone, email: emailAdd });
    console.log(resp.data.data, "+++++++++++++++++++++++++");
   
    const test = resp.data.data;
    const shouldShowModal = isLoggedIn && !test.email && !test.firstName;
    const shouldPhonemodal = gLoggedIn && !test.phoneNumber;
    setIsModalVisibleEmail(shouldShowModal);
    setIsModalVisible(shouldPhonemodal);

    const ticketId = localStorage.getItem("ticketId");

    if (!ticketId) {
      notification.error({
        message: "Something went wrong. Please try again later.",
        duration: 3,
      });

      navigate("/");
      return;
    }

    if(shouldPhonemodal || shouldShowModal){
      return
    }

    if (calculateTotalPriceNew() > 0) {
      const selectedLabels = [
        ...new Set(selectedSeats.map((seat) => seat.label)),
      ];

      const finalSeats = selectedLabels.flatMap((label) =>
        selectedSeats.filter((seat) => seat.label === label)
      );

      localStorage.setItem("finalseats", JSON.stringify(finalSeats));
      checkouthandler(calculateTotalPriceNew());
    } else {
      notification.warning({
        message: "Select Seat to proceed further",
        duration: 3,
      });
    }
  } catch (err) {
    console.log(err);
  }
};


  const prefillData = {
    email: ph || "",
    contact: emailAdd || "",
  };

  

  const checkouthandler = async (amount) => {
    try {
      const amountInPaise = amount * 100;
      // console.log(amount);
      const getKeyResponse = await axios.get(`${BASE_URL}/razpay/getkey`);
      // console.log("getKeyResponse:", getKeyResponse);

      const createOrderResponse = await axios.post(
        `${BASE_URL}/razpay/createorder`,
        { amount }
      );
      // console.log("createOrderResponse:", createOrderResponse);
      // console.log(window);
      localStorage.setItem("totalAmount", amount.toString());
      // console.log(response)
      const { key } = getKeyResponse.data;
      const { order } = createOrderResponse.data;
      const options = {
        key,
        amount: amount,
        currency: "INR",
        name: "WildcardTickets",
        description: "Razorpay tutorial",
        image: "https://wildcardtickets.com/favicon.ico",
        order_id: order.id,
        callback_url: `${BASE_URL}/razpay/paymentverification`,
        prefill: prefillData,
        notes: {
          address: "razorapy official",
        },
        theme: {
          color: "#FF275E",
        },
      };
      const razor = new window.Razorpay(options);

      razor.open();
    } catch (error) {
      console.error("Error in checkouthandler:", error);
    }
  };

  // const handlePaymentInitiation = () => {

  //   checkouthandler(100);

  // };

  const seatPricesval = ticketDetails?.seatArrangement?.seatPrices;
  const [seatQuantities, setSeatQuantities] = useState(
    seatPricesval?.map((seat) => ({ label: seat.label, value: "0" }))
  );

  const handleQuantityChange = (label, selectedQuantity) => {
    // Update the state with the new quantity for the specific label
    setSeatQuantities((prevQuantities) => {
      // Find the index of the seat in the array
      const seatIndex = prevQuantities.findIndex(
        (seat) => seat.label === label
      );
      // Create a new array with the updated quantity for the specific label
      const updatedQuantities = [...prevQuantities];
      updatedQuantities[seatIndex] = {
        label,
        value: selectedQuantity.toString(),
      };
      return updatedQuantities;
    });
  };

  // const seatRows = seatPricesval?.map((seat, index) => (
  //   <tr key={index}>
  //     <td className="gatename">
  //       {seat.label}
  //       <br></br>
  //       <span className="price">₹ {seat.price}</span>
  //     </td>
  //     <td>
  //       <select
  //         value={seat.quantity}
  //         onChange={(e) =>
  //           handleQuantityChange(seat.label, parseInt(e.target.value))
  //         }
  //         className="select"
  //       >
  //         {Array.from({ length: 10 }, (_, index) => (
  //           <option key={index} value={index}>
  //             Qty: {index}
  //           </option>
  //         ))}
  //       </select>
  //     </td>
  //   </tr>
  // ));

  const seatRows = seatPricesval?.map((seat, index) => {
    const availableQuantity = Math.max(0, seat.totalSeats - seat.bookedSeats);
    const dropdownLength = Math.min(10, availableQuantity);

    return (
      <tr key={index} className={seat.seatsAvl ? "" : "disabled-row"}>
        <td className="gatename">
          {seat.label}
          <br />
          <span className="price">₹ {seat.price}</span>
        </td>
        <td>
          {seat.seatsAvl ? (
            <select
              value={seat.quantity}
              onChange={(e) =>
                handleQuantityChange(seat.label, parseInt(e.target.value))
              }
              className="select"
            >
              {Array.from({ length: dropdownLength + 1 }, (_, i) => (
                <option key={i} value={i}>
                  Qty: {i}
                </option>
              ))}
            </select>
          ) : (
            <span className="full-message">Seats Full</span>
          )}
        </td>
      </tr>
    );
  });
  const calculateTotalPriceNew = () => {
    let total = seatQuantities?.reduce((total, seat) => {
      const seatDetails = ticketDetails.seatArrangement?.seatPrices.find(
        (s) => s.label === seat.label
      );

      if (seatDetails) {
        const seatPrice = seatDetails.price;
        const seatQuantity = parseInt(seat.value);

        // Calculate the seat price based on the conv fee type and mode
        let calculatedSeatPrice = seatPrice;

        if (ticketDetails.seatArrangement?.convFeeType === "perticket") {
          if (seatDetails.mode === "percentage") {
            calculatedSeatPrice +=
              (seatDetails.convFee / 100) * calculatedSeatPrice;
          } else if (seatDetails.mode === "fixed") {
            calculatedSeatPrice += seatDetails.convFee;
          }
        }

        return total + calculatedSeatPrice * seatQuantity;
      }

      return total;
    }, 0);

    // Apply overall conv fee after calculating individual seat prices
    if (ticketDetails?.seatArrangement?.convFeeType === "overall") {
      if (ticketDetails.mode === "percentage") {
        total += (ticketDetails.convFee / 100) * total;
      } else if (ticketDetails.mode === "fixed") {
        total += ticketDetails.convFee;
      }
    }

    return total.toFixed(2);
  };

  const calculateIndividualTotalPrice = (seatDetails, seatQuantity) => {
    const seatPrice = seatDetails.price;

    // Calculate the seat price based on the conv fee type and mode
    let calculatedSeatPrice = seatPrice;

    if (ticketDetails.seatArrangement?.convFeeType === "perticket") {
      if (seatDetails.mode === "percentage") {
        calculatedSeatPrice +=
          (seatDetails.convFee / 100) * calculatedSeatPrice;
      } else if (seatDetails.mode === "fixed") {
        calculatedSeatPrice += seatDetails.convFee;
      }
    }

    // Apply overall conv fee for individual seat prices
    if (ticketDetails.seatArrangement?.convFeeType === "overall") {
      if (ticketDetails.mode === "percentage") {
        calculatedSeatPrice +=
          (ticketDetails.convFee / 100) * calculatedSeatPrice;
      } else if (ticketDetails.mode === "fixed") {
        calculatedSeatPrice += ticketDetails.convFee;
      }
    }

    // Calculate individual total price based on the quantity
    const individualTotalPrice = calculatedSeatPrice * seatQuantity;

    // return individualTotalPrice;
    return individualTotalPrice.toFixed(2);
  };

  useEffect(() => {
    // Create a copy of selectedSeats
    const updatedSelectedSeats = [...selectedSeats];

    // Iterate over seatQuantities and update selectedSeats accordingly
    seatQuantities?.forEach((seat) => {
      const existingSeatIndex = updatedSelectedSeats.findIndex(
        (selectedSeat) => selectedSeat.label === seat.label
      );

      if (existingSeatIndex !== -1) {
        // Update existing seat
        updatedSelectedSeats[existingSeatIndex] = seat;
      } else {
        // Add new seat
        updatedSelectedSeats.push(seat);
      }
    });

    // Remove seats with value '0' from selectedSeats
    const filteredSelectedSeats = updatedSelectedSeats.filter(
      (seat) => seat.value !== "0"
    );

    // Update selectedSeats state
    setSelectedSeats(filteredSelectedSeats);
  }, [seatQuantities]);

  const convFeeData = ticketDetails?.seatArrangement;

  let convenienceFee;

  if (convFeeData.convFeeType === "overall") {
    convenienceFee = convFeeData.convFee;
  } else if (convFeeData.convFeeType === "perticket") {
    const minConvFee = Math.min(
      ...convFeeData.seatPrices.map((seat) => seat.convFee)
    );
    convenienceFee = minConvFee;
  }

  const convFeeDisplay = () => {
    if (convFeeData.convFeeType === "overall") {
      return convFeeData.mode === "percentage"
        ? `${convenienceFee}%`
        : `₹ ${convenienceFee}`;
    } else if (convFeeData.convFeeType === "perticket") {
      const seatConvenienceFee =
        convFeeData.seatPrices[0].mode === "percentage"
          ? `${convenienceFee}%`
          : `₹ ${convenienceFee}`;
      return seatConvenienceFee;
    } else {
      return "₹ 0";
    }
  };

  return (
    <div>
      {/* <h1>sdsdbjh</h1> */}
      <div className="pt-10 flex justify-between flex-col md:flex-row ">
        <div>
          <p
            className=" text-[#FF275E]"
            style={{ fontSize: "22px", fontWeight: "bold" }}
          >
            {ticketDetails?.name}
          </p>
          <p className={styles.greyText}>
            {ticketDetails?.venue},{ticketDetails?.city}{" "}
            <span className="text-[#FF275E]">|</span> {formattedEventDate}{" "}
            <span className="text-[#FF275E]">|</span> {ticketDetails?.time}
          </p>
          <br></br>
          <br></br>
          <table className={styles.borderlessTable}>
            <thead></thead>
            <tbody>{seatRows}</tbody>
          </table>
        </div>

        {/* <div className="seat-arrangement-div">
          <img src={seat} style={{width:'100%',marginTop:'70px'}}/>
        </div> */}

        <div>
          <Card
            cover={
              <img
                alt="Event Cover"
                src={ticketDetails?.eventPhoto}
                style={{ height: "200px" }}
              />
            }
            style={{ background: "whitesmoke" }}
          >
            <p className="ticketsumm">Ticket Summary</p>

            <div className="pt-5">
              <>
                {ticketDetails?.seatArrangement?.seatPrices.map(
                  (seat, index) => {
                    const seatQuantity =
                      seatQuantities?.find((sq) => sq.label === seat.label)
                        ?.value || "0";
                    const seatDetails =
                      ticketDetails?.seatArrangement?.seatPrices.find(
                        (s) => s.label === seat.label
                      );
                    const individualTotalPrice = calculateIndividualTotalPrice(
                      seatDetails,
                      seatQuantity
                    );

                    return (
                      <div
                        key={index}
                        className="flex justify-between cardContent mt-2"
                      >
                        <p>
                          {seatQuantity} X {seat.label}
                        </p>
                        <p>₹ {individualTotalPrice}</p>
                      </div>
                    );
                  }
                )}
              </>

              <br></br>
              <div className="flex justify-between cardContent">
                <p>Convenience Fee</p>
                <p>{calculateTotalPriceNew() > 0 ? convFeeDisplay() : "₹ 0"}</p>
              </div>

              <Divider />

              <div className="flex justify-between">
                <p className="total">Total</p>
                <p className="text-[#FF275E] font-bold text-lg">
                  {/* ₹ {totalPrice}  */}₹ {calculateTotalPriceNew()}
                </p>
              </div>

              <div className="buttonAlignment">
                <Button
                  type="primary"
                  style={{
                    background: "#FF275E",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                  onClick={handleConfirmAndProceed}
                >
                  Confirm & Pay
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </div>
      {/* <Button onClick={onNext}>Next</Button> */}
      <Modal
        title="Phone Number Required"
        closable={false}
        // visible={gLoggedIn && !checkPhoneNumber && isModalVisible}
        visible={isModalVisible}
        centered
        onOk={() => handleModalOk(phoneNumberInput)} // Pass the entered phone number to handleModalOk
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => handleModalOk(phoneNumberInput)}
            style={{ background: "#ff275e" }}
          >
            OK
          </Button>,
        ]}
      >
        <p>Please enter your phone number to proceed.</p>
        <Input
          placeholder="Enter your phone number"
          value={phoneNumberInput}
          onChange={(e) => setPhoneNumberInput(e.target.value)}
          validator={phoneNumberValidator}
        />
      </Modal>

      <Modal
        title="Email ID Required"
        visible={modalvisibleEmail}
        centered
        okButtonProps={{ disabled: !nameInput }}
        // onCancel={() => setIsModalVisible(false)}
        onOk={() => handleModalOkEmail(nameInput, emailInput)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => handleModalOkEmail(nameInput, emailInput)}
            style={{ background: "#ff275e" }}
            disabled={!nameInput}
          >
            OK
          </Button>,
        ]}
      >
        <p>Please enter your Name and Email to proceed.</p>
        <Input
          placeholder="Enter your Name"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <Input
          placeholder="Enter your Email"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default TicketSelectionSection;
