import styles from './styles.module.css'
const AboutUsCard=({title,content})=>{
    return(
        <div className={styles.aboutUsCard}>
            <h2 className={styles.titleOfCard}>{title}</h2>
            
            <p className={styles.contentOfCard}>{content}</p>

        </div>
    )
}

export default AboutUsCard