import React from 'react';
import './Sidebar.css';
import { CloseOutlined,MessageOutlined ,SettingOutlined , KeyOutlined,CodeSandboxOutlined ,UserOutlined ,WalletOutlined ,LikeOutlined ,NotificationOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import LoginPopup from "../../containers/login";
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector';
import { Divider,Button } from 'antd';
import { clearUser } from '../../slice';
import { useDispatch } from 'react-redux';
const Sidebar = ({ isOpen, onClose }) => {
  const dispatch=useDispatch()

  const mobile=useSelector((state)=>state?.userData?.user?.user?.phoneNumber)
  const user=useSelector((state)=>state.userData.user)

  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const openLoginModal = () => {
    setLoginModalOpen(true);
    onClose()
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
    onClose()
  };
  
  

  const handleLogout=()=>{
    dispatch(clearUser())
    // localStorage.clear()
    onClose()
  }
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className='closeButtonPos'>
      <button className='close-button' onClick={onClose}><CloseOutlined /></button>
      </div>
      <br></br>

      <h2 style={{color:'white'}} className='text-3xl'> Hey!<span style={{marginLeft:'5px'}}>{mobile}</span></h2>
      <br></br>
      <hr></hr>
      <br></br>
      <div className='flex flex-col gap-3 items-start'>
        <Link to='/'>
      <button onClick={onClose} className='sidewhite'> Home</button>
      </Link>
      <Link to='/events'>
      <button onClick={onClose} className='sidewhite'> Events</button>
      </Link>
      <Link to='aboutus'>
      <button onClick={onClose} className='sidewhite'> About Us</button>
      </Link>
      <Link to="/contactus">
      <button onClick={onClose} className='sidewhite'> Contact Us</button>
      </Link>
      <Link to="/listyourevents">
      <button onClick={onClose} className='sidewhite'> List you Event</button>
      </Link>
      <hr></hr>
      
      {/* {user?():("")} */}
      {user ? (
        <>
        <Link to='/accountmenu'>
        <button onClick={onClose}>Profile</button>
        </Link>
        <Link to='/bookingsUser'>
      <button onClick={onClose}>Bookings</button>
      </Link>
        <Link>
        <button onClick={handleLogout} className='bg-blue-500 p-2' style={{ borderRadius: '10px' ,background:'#ff275e'}}>
          Logout
        </button>
        </Link>
        </>
      ) : (
        
        <Link >
          <button onClick={openLoginModal} className=' p-2' style={{ borderRadius: '10px',background:'#ff275e' }}>
            Sign In
          </button>
        </Link>
      )}
      
      </div>
      <LoginPopup isOpen={isLoginModalOpen} onClose={closeLoginModal} />
    </div>
  );
};

export default Sidebar;