import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seatPrices: null,
};

const seatPricesSlice = createSlice({
  name: "seatPricesData",
  initialState,
  reducers: {
    setSeatPrices: (state, action) => {
        state.seatPrices = { ...action.payload };
      },
    clearSeatPrices: (state) => {
      state.seatPrices = null;
    },
  },
});

export const { setSeatPrices, clearSeatPrices } = seatPricesSlice.actions;

export default seatPricesSlice.reducer;
