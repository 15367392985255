import React from 'react';
import { Modal as AntdModal } from 'antd';
import './modal.css'

const maskStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.9)', 
};


const Modalpop = ({ isOpen, onClose, children }) => {
  return (
    <AntdModal
      visible={isOpen}
      onCancel={onClose}
    //   className="modal"
      className="custom-modal"
      closable={false}  
    //   style={{ padding: 0,background:'black'}}
      footer={null}
      width={1000}
      // style={{ top: 0,  }}
      centered
      maskStyle={maskStyle}
     
      
    >
      {children}
    </AntdModal>
  );
};

export default Modalpop;
