import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Divider,Skeleton } from 'antd';

const EventCard = ({ event }) => {

  const extractMonth = (dateString) => {
    const options = { month: 'short' };
    const date = new Date(dateString);
    const monthInWords = date.toLocaleDateString('en-US', options);
    return monthInWords.toUpperCase();
  };
  
  const extractDate = (dateString) => {
    const options = { day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  
  
  return (
    <Link  to={`/details/${event._id}`}>
    <div className="bg-white rounded-lg  overflow-hidden border border-gray-200">
      {/* Event Image */}
      <div className="relative h-64">
        <img src={event.eventPhoto} alt={event.name} className="w-full h-full object-cover" />
        {/* <button className="absolute top-2 right-2 text-red-500  bg-white rounded-full p-1 flex">
        <FontAwesomeIcon className='text-xl text-center' icon={faHeart} />
        </button> */}
        <p className="absolute top-2 left-2 text-black font-bold bg-white px-2 py-1 rounded-lg">{event.eventCategory.charAt(0).toUpperCase() + event.eventCategory.slice(1)}</p>
      </div>
      {/* Event Details */}
      <div >
        <div className='flex justify-start gap-2 p-4'>
            <div className='flex flex-col items-center'>
                <p style={{fontSize:'22px',fontWeight:"bold",fontStyle:'normal',color:'#ff275e'}}>{extractMonth(event.eventDate)}</p>
                
                <p style={{fontSize:'20px',fontWeight:"bold",fontStyle:'normal',color:'#000'}}>{extractDate(event.eventDate)}</p>
            </div>
            <Divider type='vertical' style={{height:'4em'}}/>
        <div>
        <p style={{fontSize:'18px',fontWeight:'500',color:'#000'}}>{event.name}</p>
        <p style={{fontSize:'16px',color:'#969696',fontWeight:'normal'}}>{event.venue}</p>
        </div>
        </div>

        <div className="flex justify-between items-center bg-gray-100 p-2">
          <div className=" px-3 py-1 rounded-full">
            <p style={{color:'#424242',fontSize:'16px',fontWeight:"600"}}>₹ {event.price} onwards</p>
          </div>
          <Link  to={`/details/${event._id}`}>
          <button 
          style={{width:'90px',color:'white',backgroundColor:'#FF275E',padding:'10px',borderRadius:'6px',fontSize:'16px',fontWeight:600}}>
            BOOK
          </button>
          </Link>
        </div>
      </div>
    </div>
    </Link>
  );
};

export default EventCard;
