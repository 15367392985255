import { Spin, Skeleton,Empty } from "antd";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import React from "react";
import "../../global.css";
import { filterTickets } from "../../requests";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

const EventCard = lazy(() => import("../../components/EventCard"));

const Viewmore = () => {
  
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
//   const city = searchParams.get("location");
const city = useSelector((state) => state?.userData?.city);

  const [loading, setLoading] = useState(true);
  const [filteredApiTickets, setfiltered] = useState([]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const goBack = () => {
    navigate(-1);
  };

  

  const displayCity = city ? city.toUpperCase() : "DELHI";
  const capitalizedCategory = capitalizeFirstLetter(category);

  // const filteredEvents = eventList.filter(
  //   (event) => event.genre.toLowerCase() === category.toLowerCase()
  // );

  const SkeletonFallback = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7">
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );

  const EventCardGrid = ({ events }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7">
      {events.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </div>
  );

  const ticketFiltered = async () => {
    try {
      const resp = await filterTickets({ eventCategory: category, city: city });

      setLoading(true);
      setfiltered(resp.data.filteredTickets);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // console.log(filteredApiTickets, "---------------------");

  useEffect(() => {
    ticketFiltered();
  }, [city]);

  return (
    <div className="container mx-auto p-4" style={{ marginTop: "100px" }}>
      <button className="mb-10" onClick={goBack}>
        <FontAwesomeIcon icon="fa-arrow-left" /> Back
      </button>

      <div>
        <p className="sm:text-2xl md:text-3xl lg:text-3xl font-bold mb-8">
          {capitalizedCategory} in{" "}
          <span style={{ color: "#ff275e" }}>{`"${displayCity}"`}</span>
        </p>

        <Suspense fallback={<SkeletonFallback />}>
          {!loading ? (
            filteredApiTickets.length > 0 ? (
              <EventCardGrid events={filteredApiTickets} />
            ) : (
              <Empty
              description={
                <p className="text-xl text-gray-500">
                  Sorry, no events for this category.
                </p>
              }
            />
            )
          ) : (
            <SkeletonFallback />
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Viewmore;
