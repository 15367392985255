import "../../global.css";
import styles from "./styles.module.css";
import { setSeatPrices } from "../../seatPriceslice";
import {
  Card,
  Space,
  Divider,
  Collapse,
  Spin,
  Skeleton,
  Button,
  Modal,
  Input,
  message,
} from "antd";
import {
  EnvironmentOutlined,
  CompassOutlined,
  BorderlessTableOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import LoginPopup from "../login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import EventCard from "../../components/EventCard";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loadercustom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { useNavigate } from "react-router-dom";
import {
  ticketDetail,
  tickets,
  filterTickets,
  googleUserDetailsUpdate,
  addDetails,
  getAlldetails,
} from "../../requests";
import { useParams, Link } from "react-router-dom";

const Details = () => {
  const [loading, setLoading] = useState(true);
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [AllDetails, setalldetails] = useState("");
  const [cityFilter, setcityfilter] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [allTickets, setalltickets] = useState([]);
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [emailInput, setEmailInput] = useState("");
  const [DetailsOfuser, setDetailsofUser] = useState("");
  const dispatch = useDispatch();
  const [nameInput, setNameInput] = useState("");
  const [modalvisibleEmail, setIsModalVisibleEmail] = useState(false);

  const checkPhoneNumber = useSelector(
    (state) => state?.userData?.user?.phoneNumber
  );

  const emailAdd = useSelector((state) => state?.userData?.user?.email);

  const checkEmail = useSelector((state) => state?.userData?.user?.user?.email);

  const phone = useSelector(
    (state) => state?.userData?.user?.user?.phoneNumber
  );

  const nameofuser = useSelector(
    (state) => state?.userData?.user?.user?.firstName
  );

  localStorage.setItem("ticketId", AllDetails._id);

  const storedSeats = localStorage.getItem("selectedSeats");

  const openLoginModal = () => {
    setLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setLoginModalOpen(false);
  };

  const handleButtonClick = () => {
    if (isLoggedIn || gLoggedIn) {
      navigate("/bookticket", { state: { ticketDetails: AllDetails } });
     
    } else {
      openLoginModal();
    }
  };

  const city = useSelector((state) => state?.userData?.city);
  const isLoggedIn = useSelector((state) => state?.userData?.user?.user?._id);
  const gLoggedIn = useSelector((state) => state?.userData?.user?.userID);

  const { eventId } = useParams();
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const items = [
    {
      key: "1",
      label: (
        <p style={{ color: "#7b7b7b", fontSize: "26px", fontWeight: "600" }}>
          Events terms and conditions
        </p>
      ),
      children: (
        <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
          <div>
            <p className="font-bold">Valid ID Proof</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Attendees are required to carry a valid ID proof with them to
                gain entry to the event. The ID must match the name on the
                ticket.
              </span>
            </p>

            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Attendees are required to carry a valid ID proof with them to
                gain entry to the event. The ID must match the name on the
                ticket.
              </span>
            </p>
          </div>

          <div>
            <p className="font-bold">No Refunds</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                No refunds will be issued for purchased tickets, even in the
                event of rescheduling or cancellation.
              </span>
            </p>
          </div>

          <div>
            <p className="font-bold">Security Procedures</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Security procedures, including frisking, are at the discretion
                of the event management and must be followed.
              </span>
            </p>
          </div>

          <div>
            <p className="font-bold">Prohibited Items</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Prohibited items include, but are not limited to weapons,
                knives, guns, fireworks, helmets, laser devices, bottles, and
                musical instruments.
              </span>
            </p>

            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Attendees found in possession of such items may be ejected from
                the venue with or without the owner.
              </span>
            </p>
          </div>

          <div>
            <p className="font-bold">Liability</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                The sponsors, performers, and organizers are not liable for any
                injury or damage occurring during the event. Any claims related
                to such incidents will be settled in the courts in Mumbai.
              </span>
            </p>
          </div>

          <div>
            <p className="font-bold">Inebriation</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Entry may be denied to individuals in an inebriated state, and
                the decision lies with the event organizers.
              </span>
            </p>
          </div>

          <div>
            <p className="font-bold">Late Entry</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Organizers reserve the right to deny late entry to the event.
                Please arrive on time.
              </span>
            </p>
          </div>

          <div>
            <p className="font-bold">Venue Rules</p>
            <p>
              <FontAwesomeIcon icon="fa-ticket" />{" "}
              <span style={{ marginLeft: "2px" }}>
                Venue rules and regulations must be adhered to by all attendees.
              </span>
            </p>
          </div>
        </div>
      ),
    },
  ];

  const extractMonth = (dateString) => {
    const options = { month: "short" };
    const date = new Date(dateString);
    const monthInWords = date.toLocaleDateString("en-US", options);
    return monthInWords.toUpperCase();
  };

  const extractDate = (dateString) => {
    const options = { day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const detailsTicket = async () => {
    try {
      setrefresh(true);
      const resp = await ticketDetail(eventId);
      setalldetails(resp.data.ticket);
    } catch (err) {
      console.log(err);
    } finally {
      setrefresh(false);
      setLoading(false);
    }
  };

  // console.log(refresh, "{{{{{{{[dsvrfg ue");

  const AllticketsApi = async () => {
    try {
      const resp = await tickets();

      setalltickets(resp.data.data.tickets);
    } catch (err) {
      console.log(err);
    }
  };

  const filteredTicketsApi = async () => {
    try {
      const resp = await filterTickets({ city: city });

      setcityfilter(resp.data.filteredTickets);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    detailsTicket();
  }, [eventId]);
  useEffect(() => {
    if (city) {
      filteredTicketsApi(city);
    } else {
      AllticketsApi();
    }
  }, [city]);

  useEffect(() => {
    if (city) {
      filteredTicketsApi(city);
    }
  }, [city]);

  // console.log(AllDetails.venueMapLocation)

  useEffect(() => {
    console.log(AllDetails); // Log the entire object
    const maploc = AllDetails?.venueMapLocation;
  
  }, [AllDetails]);

  const phoneNumberValidator = (_, value) => {
    const isNumeric = /^[0-9]*$/; // Regex to check if the value is numeric

    if (!value) {
      return Promise.reject("Please enter your phone number.");
    } else if (!isNumeric.test(value)) {
      return Promise.reject("Please enter a valid numeric phone number.");
    } else if (value.length !== 10) {
      return Promise.reject("Phone number must be 10 digits long.");
    }

    return Promise.resolve();
  };

  const googleUser = useSelector((state) => state?.userData?.user);
  const gid = googleUser?.userID;

  const handleModalOk = async () => {
    try {
      await phoneNumberValidator(null, phoneNumberInput);
      const resp = await googleUserDetailsUpdate(
        { phoneNumber: phoneNumberInput },
        gid
      );
      console.log(resp);

      if (resp.status === 200) {
        handleModalCancel();
        message.success("Phone number updated successfully");
      } else {
        console.log("Unexpected response status:", resp.status);
        message.error("Please enter phone number to proceed.");
      }
    } catch (err) {
      console.log(err);
      message.error("Please enter phone number to proceed.");
    }
  };

  const handleModalOkEmail = async () => {
    try {
      if (!nameInput) {
        message.error("Please Enter Your Name to Proceed.");
        return;
      }
      const resp = await addDetails(
        { firstName: nameInput, email: emailInput },
        phone
      );
      console.log(resp);
      if (resp.status === 200) {
        message.success("Details updated successfully");
        handleModalCancel();
      } else {
        console.log("Unexpected response status:", resp.status);
        message.error("Please enter Details to proceed.");
      }
    } catch (err) {
      console.log(err);
      message.error("Please Enter Email to proceed.");
    }
  };

  const detailsOfUser = async () => {
    try {
      const resp = await getAlldetails({ phoneNumber: phone, email: emailAdd });
      // console.log(resp.data.data, "+++++++++++++++++++++++++");
      setDetailsofUser(resp.data.data);
      // const test = resp.data.data;
      // const shouldShowModal = isLoggedIn && !test.email && !test.firstName;
      // setIsModalVisibleEmail(shouldShowModal);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    detailsOfUser();
  }, []);



  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsModalVisibleEmail(false);
  };

 

  const goBack = () => {
    navigate(-1);
  };

 

  const venueMapLocation = encodeURIComponent(AllDetails?.venueMapLocation);

  return (
    <>
      {/* <Spin spinning={refresh} fullscreen /> */}
      {loading ? (
        <div
          className="container"
          style={{
            marginTop: "100px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          {/* <Spin fullscreen /> */}
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          <div style={{ backgroundColor: "rgba(58, 58, 58)" }}>
            <img
              src={AllDetails.eventPhoto}
              style={{
                backgroundColor: "rgba(58, 58, 58)",
                height: "430px",
                width: "100vw",
                objectFit: "cover",
                opacity: 0.4,
              }}
              className={styles.imageFade}
            />
          </div>
          <div
            className={`container ${styles.mainContainer}`}
            // style={{ top: "50%", left: 0, transform: "translateY(-18%)" }}
          >
            <div className={styles.TopSection}>
              <div
                // style={{ display: "flex", flexDirection: "column", gap: "20px" }}
                className={styles.leftSection}
              >
                {/* <h1 style={{marginTop:'-50px',marginBottom:'60px',fontSize:'18px',color:'#fff',fontWeight:'500'}}>Back</h1> */}
                <div className={styles.backButton}>
                  <button onClick={goBack} className={styles.backButtonArrow}>
                    <FontAwesomeIcon icon="fa-arrow-left" /> Back
                  </button>
                </div>
                <img
                  src={AllDetails.thumbnailPhoto}
                  style={{
                    // height: "390px",
                    // width: "405px",
                    boxShadow: "0px 6px 11px 1px rgba(0, 0, 0, 0.25)",
                  }}
                  className={styles.mobileImage}
                />

                <div className={styles.mobilelayout}>
                  <p className={styles.respText}>
                    {AllDetails.name}
                    <span style={{ color: "#ff275e" }}>.</span>{" "}
                  </p>
                </div>

                <div style={{ width: "350px" }} className={styles.card}>
                  <div className={styles.cardUp}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <p className="text-xl font-bold text-[#FF275E]">
                        {extractMonth(AllDetails.eventDate)}
                      </p>
                      <p className="text-xl font-bold">
                        {extractDate(AllDetails.eventDate)}
                      </p>
                    </div>
                    <Divider type="vertical" style={{ height: "3em" }} />
                    <h2
                      className=" font-semibold "
                      style={{ fontSize: "18px", fontWeight: "500" }}
                    >
                      {AllDetails.name}
                    </h2>
                  </div>
                  {/* <Divider/> */}
                  <div className={styles.lowerSec}>
                    <div
                      className=" bg-gray-100 "
                      style={{
                        // background: "gray",
                        padding: "8px 20px",
                        borderRadius: "4px",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#424242",
                      }}
                    >
                      ₹ {AllDetails.price}
                    </div>
                  </div>
                </div>
                <div className={styles.nonMobile}>
                  <Card title="Event Location" style={{ width: 350 }}>
                    <Space direction="vertical">
                      <div>
                        {/* Replace the iframe src with the actual map source */}
                        {AllDetails._id === "6585014a91399ee8cda1d2a1" ? (
                          // Custom iframe for the specific condition
                          <iframe
                            title="Event Map - Custom"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3662.923703071674!2d85.29746427409872!3d23.354778578942227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e0596ded9b8f%3A0xd45a9a78ce8f795!2sThe%20Kav&#39;s%20Restaurant!5e0!3m2!1sen!2sin!4v1703499912586!5m2!1sen!2sin"
                            width="100%"
                            height="200"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        ) : (
                          // Default iframe for other conditions
                          <iframe
                            title="Event Map - Default"
                            src={AllDetails?.venueMapLocation}
                            width="100%"
                            height="200"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                          ></iframe>
                        )}
                      </div>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <EnvironmentOutlined style={{ fontSize: "24px" }} />
                          <span>
                            {AllDetails.venue} {AllDetails.city}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                          }}
                        >
                          <CompassOutlined style={{ fontSize: "24px" }} />
                          <a
                            href={AllDetails.navigationLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Navigate
                          </a>
                        </div>
                      </div>
                    </Space>
                  </Card>
                </div>
              </div>

              <div className={styles.secondSection}>
                <div className={styles.descriptionHead}>
                  <div className={styles.nonMobile}>
                    <p className={styles.respText}>
                      {AllDetails.name}
                      <span style={{ color: "#ff275e" }}>.</span>{" "}
                    </p>
                  </div>
                  <div className={styles.description}>
                    <p className="sixtpx">
                      {AllDetails?.eventCategory?.charAt(0)?.toUpperCase() +
                        AllDetails?.eventCategory?.slice(1)}{" "}
                      Event
                    </p>
                    <p className="twentpx">Description</p>
                    <div>
                      <p
                        className="text-sm text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: AllDetails.description,
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "80px",
                        marginTop: "50px",
                      }}
                    >
                      <div>
                        <p className="twentpx">Time</p>
                        <p className="text-sm text-gray-500">
                          {AllDetails.time}
                        </p>
                      </div>
                      <div>
                        <p className="twentpx">Duration</p>
                        <p className="text-sm text-gray-500">
                          {AllDetails.duration}
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        marginTop: "60px",
                      }}
                    >
                      <p className="twentpx">Artist</p>
                      <img
                        style={{ width: "70px", height: "77px" }}
                        src={AllDetails.ArtistPhoto}
                        alt="artist"
                      />
                      <p className="text-sm text-gray-500">
                        {AllDetails.artistName}
                      </p>
                    </div>
                  </div>
                  <Divider dashed style={{ height: "1px", color: "#d9d9d9" }} />

                  <div className={styles.proceedBtn}>
                    <button
                      className="bg-[#FF275E] text-white px-8 py-2 rounded"
                      onClick={handleButtonClick}
                    >
                      Proceed
                    </button>
                  </div>
                  <br></br>
                  <div className={styles.mobilelayout}>
                    <Card title="Event Location" style={{ width: "100%" }}>
                      <Space direction="vertical">
                        <div>
                          {/* Replace the iframe src with the actual map source */}
                          {AllDetails._id === "6585014a91399ee8cda1d2a1" ? (
                            // Custom iframe for the specific condition
                            <iframe
                              title="Event Map - Custom"
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3662.923703071674!2d85.29746427409872!3d23.354778578942227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f4e0596ded9b8f%3A0xd45a9a78ce8f795!2sThe%20Kav&#39;s%20Restaurant!5e0!3m2!1sen!2sin!4v1703499912586!5m2!1sen!2sin"
                              width="100%"
                              height="200"
                              frameBorder="0"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                          ) : (
                            // Default iframe for other conditions
                            <iframe
                              title="Event Map - Default"
                              src={AllDetails?.venueMapLocation}
                              width="100%"
                              height="200"
                              frameBorder="0"
                              style={{ border: 0 }}
                              allowFullScreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                          )}
                        </div>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <EnvironmentOutlined style={{ fontSize: "24px" }} />
                            <span>
                              {AllDetails.venue} {AllDetails.city}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center",
                            }}
                          >
                            <CompassOutlined style={{ fontSize: "24px" }} />
                            <a
                              href={AllDetails.navigationLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Navigate
                            </a>
                          </div>
                        </div>
                      </Space>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.lowSection}>
            <div className="container">
              <Collapse
                ghost
                items={items}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusOutlined
                      style={{ color: "#ff275e", paddingTop: "16px" }}
                    />
                  ) : (
                    <PlusOutlined
                      style={{ color: "#ff275e", paddingTop: "16px" }}
                    />
                  )
                }
              />
            </div>

            {/* <div
              className="container"
              style={{ paddingTop: "70px", paddingBottom: "70px" }}
            >
              <p className="trendingText">Recommended for you</p>
              <Carousel responsive={responsive}>
                {cityFilter
                  ? // Map cityFilter if it exists
                    cityFilter.map((event) => (
                      <div className={styles.cardgap} key={event.id}>
                        <EventCard event={event} />
                      </div>
                    ))
                  : // Map allTickets if cityFilter doesn't exist
                    allTickets.map((event) => (
                      <div className={styles.cardgap} key={event.id}>
                        <EventCard event={event} />
                      </div>
                    ))}
              </Carousel>
            </div> */}
          </div>
        </div>
      )}
      <LoginPopup isOpen={isLoginModalOpen} onClose={closeLoginModal} />

      {/* <Modal
        title="Phone Number Required"
        closable={false}
        visible={gLoggedIn && !checkPhoneNumber && isModalVisible}
        centered
        onOk={() => handleModalOk(phoneNumberInput)} // Pass the entered phone number to handleModalOk
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => handleModalOk(phoneNumberInput)}
            style={{ background: "#ff275e" }}
          >
            OK
          </Button>,
        ]}
      >
        <p>Please enter your phone number to proceed.</p>
        <Input
          placeholder="Enter your phone number"
          value={phoneNumberInput}
          onChange={(e) => setPhoneNumberInput(e.target.value)}
          validator={phoneNumberValidator}
        />
      </Modal>

      <Modal
        title="Email ID Required"
        // closable
        visible={modalvisibleEmail}
        // visible={isLoggedIn && (!checkEmail || !DetailsOfuser.email) &&(!nameofuser || !DetailsOfuser.firstName) && isModalVisible}
        centered
        okButtonProps={{ disabled: !nameInput }}
        // onCancel={() => setIsModalVisible(false)}
        onOk={() => handleModalOkEmail(nameInput, emailInput)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => handleModalOkEmail(nameInput, emailInput)}
            style={{ background: "#ff275e" }}
            disabled={!nameInput}
          >
            OK
          </Button>,
        ]}
      >
        <p>Please enter your Name and Email to proceed.</p>
        <Input
          placeholder="Enter your Name"
          value={nameInput}
          onChange={(e) => setNameInput(e.target.value)}
          style={{ marginBottom: "10px" }}
        />
        <Input
          placeholder="Enter your Email"
          value={emailInput}
          onChange={(e) => setEmailInput(e.target.value)}
        />
      </Modal> */}
    </>
  );
};
export default Details;
