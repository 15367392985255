import { Spin, Skeleton, Empty, notification } from "antd";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import React from "react";
import "../../global.css";
import { filterTickets } from "../../requests";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

const EventCard = lazy(() => import("../../components/EventCard"));

const Search = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const city = searchParams.get("location");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [loading, setLoading] = useState(true);
  const [filteredApiTickets, setfiltered] = useState([]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const goBack = () => {
    navigate(-1);
  };

  const cities = useSelector((state) => state?.sideData?.cities);
  const caterories = useSelector((state) => state?.sideData?.categories);
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };
  const handleSearch = () => {
    if (selectedCategory && selectedLocation) {
      navigate(
        `/search?category=${selectedCategory}&location=${selectedLocation}`
      );
    } else {
      notification.error({
        message: "Search Error",
        description: "Both category and location are required for search.",
      });
    }
  };

  const displayCity = city ? city.toUpperCase() : "DELHI";
  const capitalizedCategory = capitalizeFirstLetter(category);

  // const filteredEvents = eventList.filter(
  //   (event) => event.genre.toLowerCase() === category.toLowerCase()
  // );

  const SkeletonFallback = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7 mt-10 mb-10">
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );

  const EventCardGrid = ({ events }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7 ">
      {events.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </div>
  );

  const ticketFiltered = async () => {
    try {
      setLoading(true)
      const resp = await filterTickets({ eventCategory: category, city: city });

      setLoading(true);
      const filteredTickets = resp.data.filteredTickets.filter(
        (ticket) => ticket.status === 'publish'
      );
      setfiltered(filteredTickets);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // console.log(filteredApiTickets, "---------------------");

  useEffect(() => {
    ticketFiltered();
  }, [city]);

  return (
    <div className="container mx-auto p-4" style={{ marginTop: "100px" }}>
       <button className="mb-3" onClick={goBack}>
        <FontAwesomeIcon icon="fa-arrow-left" /> Back
      </button>
      <div
        className="p-6 rounded-lg shadow-md flex flex-col md:flex-row md:items-center md:justify-between mx-auto w-full md:w-1/2 "
        style={{ background: "#f6f6f6" }}
      >
        <div className="mb-4 md:w-3/4 md:mr-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative">
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700"
              >
                <span style={{ marginLeft: "11px" }}>Category</span>
              </label>
              <select
                id="category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="focus:outline-none block w-full mt-1 p-2 border-0 rounded-none focus:ring-indigo-500 focus:border-indigo-500 bg-transparent"
              >
                <option value="">Select Category</option>
                {caterories.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="relative">
              <label
                htmlFor="eventType"
                className="block text-sm font-medium text-gray-700"
              >
                <span style={{ marginLeft: "11px" }}>Location</span>
              </label>
              <select
                id="eventType"
                name="eventType"
                value={selectedLocation}
                onChange={handleLocationChange}
                className="focus:outline-none block w-full mt-1 p-2 border-0 rounded-none focus:ring-indigo-500 focus:border-indigo-500 bg-transparent"
              >
                <option value="">Select Location</option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <button
          className="text-white font-medium p-3 rounded-md hover:bg-red-600"
          style={{ backgroundColor: "#FF275E" }}
          onClick={handleSearch}
        >
          Search
        </button>
      </div>
     <br></br>

      <div>
        <h1 className="text-3xl font-bold mb-8">
          {capitalizedCategory} in{" "}
          <span style={{ color: "#ff275e" }}>{`"${displayCity}"`}</span>
        </h1>

        <Suspense fallback={<SkeletonFallback />}>
          {!loading ? (
            filteredApiTickets.length > 0 ? (
              <EventCardGrid events={filteredApiTickets} />
            ) : (
              <Empty
                description={
                  <p className="text-xl text-gray-500">
                    Sorry, no events for this category.
                  </p>
                }
              />
            )
          ) : (
            <SkeletonFallback />
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Search;
