import React from 'react';
import styles from './styles.module.css'
import { useState,useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { globalSearch } from '../../requests';
import { useNavigate} from 'react-router-dom';
import {
    CloseOutlined 
  } from '@ant-design/icons';
 
  import { Spin } from 'antd';
  import { LoadingOutlined } from '@ant-design/icons';

const SearchModal = ({ isOpen, onClose }) => {

  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
const navigate=useNavigate()
const [loading, setLoading] = useState(false);




const handleSearch = async () => {
  setLoading(true); 
  try {
    if (searchQuery.trim() !== '') {
      const resp = await globalSearch(searchQuery);
      // console.log(resp.data.matchingTickets)
      setSuggestions(resp.data.matchingTickets)
    } else {
      setSuggestions([]);
    }
  } catch (err) {
    console.log(err)
  }finally{
    setLoading(false);
  }
}


useEffect(() => {
  
  handleSearch();
}, [searchQuery]);


const navigateToDetails = (id) => {
  onClose();
  navigate(`/details/${id}`);
  setSearchQuery('');
  setSuggestions([]);
};

const handleClose = () => {
  setSearchQuery('');
  setSuggestions([]);
  onClose();
};

 
  
return (
    <div className={`fixed top-0 left-0 w-full h-full bg-white ${isOpen ? '' : 'hidden'}`}>
      <div className={` top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ${styles.searchBarModal} p-8 rounded-lg shadow-lg relative transition-all duration-300 bg-gray-100`} >
        <div className='closeButtonPos'>
          <button onClick={handleClose} style={{ color: 'black' }}><CloseOutlined /></button>
        </div>
        <br></br>
        <div className="relative"> 
        <div className="flex items-center bg-white rounded-md overflow-hidden">
      
          <input
            type="text"
            placeholder="Search for Movies, Events, Sports and Plays"
            className="w-full py-2 px-4 border-none focus:outline-none"
            style={{ color: 'black' }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          
          <button className="text-white px-4 py-2" style={{ background: '#ff275e' }}><FontAwesomeIcon icon="fa-magnifying-glass" /></button>
        </div>

       
      {searchQuery.trim() !== '' && <div className={styles.searchres}>
          {loading && (
            <div className="flex justify-center mt-4">
              <Spin 
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
          /> 
            </div>
          )}
          <ul>
          {!loading && suggestions.length === 0 && (
                <li className={styles.noresult}>No results found</li>
              )}
            {!loading && suggestions.map((suggestion, index) => (
              <li key={suggestion._id} onClick={() => navigateToDetails(suggestion._id)} className={styles.list}>
                {suggestion.name}
              </li>
            ))}
          </ul>
          
        </div>
}
</div>


       
      </div>
    </div>
  );
};

export default SearchModal;