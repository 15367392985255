import React from 'react';
import styles from './styles.module.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const CategoryCard = ({ category, image, icon }) => {
  const categorySlug = category.toLowerCase();
  return (
    <Link to={`${categorySlug}`}>
    <div className={styles.categorycard}>
      <div className={styles.categorybackground}>
        <img src={image} alt={category} />
      </div>
      <div className={styles.categorycontent}>
        <div className={styles.categoryicon }><FontAwesomeIcon icon={icon}/></div>
        <p className={styles.categorytext}>{category}</p>
      </div>
    </div>
    </Link>
  );
};

export default CategoryCard;
