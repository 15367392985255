import styles from "./styles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Ticket from "../../components/ticket";
import html2canvas from "html2canvas";
import { useEffect, useState } from "react";
import {message} from "antd"
import { Link, useNavigate } from "react-router-dom";

const ConfirmationSection = ({ onPrev }) => {
  const [detail, setDetail] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    
    const bookedTicketDetail = localStorage.getItem("bookedTicketDetail");

    
    const parsedDetail = bookedTicketDetail
      ? JSON.parse(bookedTicketDetail)
      : null;
      
      localStorage.removeItem("ticketId");
      localStorage.removeItem("selectedSeats");
    
    setDetail(parsedDetail);
  }, []); 

  useEffect(() => {
    const handlePopState = () => {
      // Modify the browser history to prevent going back
      navigate.replaceState(null, document.title, window.location.href);
    };

    // Attach the event listener
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const eventDateObject = new Date(detail.date);
  const formattedEventDate = eventDateObject.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const downloadTicket = () => {
    console.log("Download Ticket clicked");
  
    const ticketContainer = document.getElementById("ticket-container");
  
    if (ticketContainer) {
      html2canvas(ticketContainer).then((canvas) => {
        const ticketImage = canvas.toDataURL("image/png");
        console.log("Ticket image captured:", ticketImage);
  
        const link = document.createElement("a");
        link.href = ticketImage;
        link.download = "wildcardticket.png";
        link.click();
        console.log("Download initiated");

        message.success('Ticket downloaded successfully!');
      });
    } else {
      console.log("Ticket container not found");
    }
  };

  return (
    <>
    
    <div className={styles.TicketConfirmationFullLayout}>
    <h2 style={{ color: "green" }}  className={styles.confirmedText}>
          <FontAwesomeIcon icon="fa-circle-check" /> Ticket Confirmed
        </h2>

        <button onClick={downloadTicket} className={styles.downloadButton} > <FontAwesomeIcon icon="fa-arrow-down"/> Download</button>
    </div>
      {/* <Loader loading={refresh} /> */}
      <div id="ticket-container">
      <Ticket
        name={detail.eventName}
        time={detail.time}
        // address={fakeData.address}
        qrImage={detail.qrCode}
        seats={detail.seats}
        date={formattedEventDate}
        venue={detail.venue}
        city={detail.city}
      />
      </div>
      



      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "16px",
          marginTop: "10px",
          fontWeight: 600,
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span style={{ color: "#ff275e" }}>
          NOTE:- Please Show this Ticket at the Gate!!
        </span>
        <Link to="/bookingsUser">
          <span>
            You can view your Ticket{" "}
            <span
              style={{
                fontSize: "18px",
                textDecoration: "underline",
                color: "#ff275e",
              }}
            >
              HERE
            </span>
          </span>
        </Link>
      </div>

      {/* <div className="pt-30" style={{display:'flex',justifyContent:'center'}}>
      <div className={styles.ticketSection}>
        <h2 style={{ color: "green" }}>
          <FontAwesomeIcon icon="fa-circle-check" /> Ticket Confirmed
        </h2>
        <br></br>
        <div className={styles.ticket}>
          <div style={{display:'flex',flexDirection:'column',alignItems:'center',padding:'0 10px'}}>
            <img
              src={qr}
              alt="qr"
              style={{ height: "200px", width: "200px" }}
            />
            <br></br>
            <div style={{ display: "flex", gap: "25px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  alignItems:'center'
                }}
              >
                <p style={{fontSize:'12px',fontWeight:'400',fontFamily:'Courier Prime'}}>ENTRANCE</p>
                <p style={{fontSize:'23px',fontWeight:'700',fontFamily:'Courier Prime'}}>3</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  alignItems:'center'
                }}
              >
                <p style={{fontSize:'12px',fontWeight:'400',fontFamily:'Courier Prime'}}>BLOCK</p>
                <p style={{fontSize:'23px',fontWeight:'700',fontFamily:'Courier Prime'}}>A</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  alignItems:'center'
                }}
              >
                <p style={{fontSize:'12px',fontWeight:'400',fontFamily:'Courier Prime'}}>ROW</p>
                <p style={{fontSize:'23px',fontWeight:'700',fontFamily:'Courier Prime'}}>9</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  alignItems:'center'
                }}
              >
                <p style={{fontSize:'12px',fontWeight:'400',fontFamily:'Courier Prime'}}>SEAT</p>
                <p style={{fontSize:'23px',fontWeight:'700',fontFamily:'Courier Prime'}}>13</p>
              </div>
            </div>
          </div>
          <Divider
            type="vertical"
            dashed
            style={{ height: "20em", borderColor: "#a1a1a1", borderWidth: "2px" }}
            className={styles.verticalDiv}
          />
          <Divider
            // type="vertical"
            dashed
            // style={{ height: "20em", borderColor: "#a1a1a1", borderWidth: "2px" }}
            className={styles.horizonDiv}
          />
          <div className={styles.ticketDetails}>
            <p style={{fontSize:'50px',fontWeight:'700',textTransform:'uppercase',fontFamily:'Courier Prime'}}>Live music by ar rahman</p>
            <div style={{display:'flex',gap:'5px',flexDirection:'column'}}>
              <p style={{fontSize:'23px',fontWeight:'700',fontFamily:'Courier Prime'}}>Tues 30th Nov 2021 <Divider type="vertical"/>7:00 - 11:00 pm</p>
              <p style={{fontSize:'20px',fontWeight:'400',fontFamily:'Courier Prime'}}>Sardar Patel Stadium</p>
            </div>
            <div className={styles.seatPos}>
              <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                <p style={{fontSize:'17px',fontWeight:'400',fontFamily:'Courier Prime'}}>ENTRANCE</p>
                <p style={{fontSize:'34px',fontWeight:'700',fontFamily:'Courier Prime'}}>3</p>
              </div>
              <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
                <p style={{fontSize:'17px',fontWeight:'400',fontFamily:'Courier Prime'}}>ROW</p>
                <p style={{fontSize:'34px',fontWeight:'700',fontFamily:'Courier Prime'}}>3</p>
              </div>
              <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
              <p style={{fontSize:'17px',fontWeight:'400',fontFamily:'Courier Prime'}}>HALL</p>
                <p style={{fontSize:'34px',fontWeight:'700',fontFamily:'Courier Prime'}}>3</p>
              </div>
              <div style={{display:'flex',alignItems:'center',gap:'5px'}}>
              <p style={{fontSize:'17px',fontWeight:'400',fontFamily:'Courier Prime'}}>SEAT</p>
                <p style={{fontSize:'34px',fontWeight:'700',fontFamily:'Courier Prime'}}>3</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
    </>
  );
};

export default ConfirmationSection;
