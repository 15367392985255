import Header from "../components/Header";
import Footer from "../components/footer";

const BasicLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div>
        {children}
        <Footer />
      </div>
    </div>
  );
};

export default BasicLayout;
