import React, { useState, useEffect } from "react";
import "./theme.css";

import {
  Breadcrumb,
  Button,
  Divider,
  Card,
  Form,
  Row,
  Col,
  Radio,
  Input,
  Spin,
} from "antd";
import "../../global.css";
import styles from "./styles.module.css";
import Img from "../../assets/images/cov.jpg";
import qr from "../../assets/images/qr.png";
import {
  CreditCardOutlined,
  BankOutlined,
  WalletOutlined,
  GooglePayOutlined,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationSection from "../confirm";
import TicketSelectionSection from "../ticketSelection";
import PaymentSection from "../paymentsection";
import { useLocation, useNavigate } from "react-router-dom";

const onFinish = (values) => {
  console.log("Received values:", values);
  // Add your logic for handling the form submission here
};



const BookTicket = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const ticketDetails = location.state?.ticketDetails;
  // const location = useLocation();
  useEffect(() => {
    // Check if the route path includes "/bookings/confirmed"
    if (location.pathname.includes("/confirmed")) {
      setCurrentStep(2); // Set the initial step to 2 (Confirmation step)
    }
  }, [location.pathname]);

console.log(currentStep)

  const breadcrumbItems = [
    {
      title: (
        <span className="breadHead">
          <span
            className={`breadcrumb-number ${currentStep === 0 ? "active" : ""}`}
          >
            1
          </span>{" "}
          <span className={styles.noDisplay}>Ticket / </span>Seat Selection
        </span>
      ),
      active: currentStep === 0,
      section: (
        <TicketSelectionSection
          onNext={() => setCurrentStep(1)}
          ticketDetails={ticketDetails}
        />
      ),
    },
    {
      title: (
        <span className="breadHead">
          <span
            className={`breadcrumb-number ${currentStep === 1 ? "active" : ""}`}
          >
            2
          </span>{" "}
          Payment
        </span>
      ),
      active: currentStep === 1,
      section: (
        <PaymentSection
          // onNext={() => setCurrentStep(2)}
          onPrev={() => setCurrentStep(0)}
        />
      ),
    },
    {
      title: (
        <span className="breadHead">
          <span
            className={`breadcrumb-number ${currentStep === 2 ? "active" : ""}`}
          >
            3
          </span>{" "}
          Confirmation
        </span>
      ),
      active: currentStep === 2,
      section: <ConfirmationSection onPrev={() => setCurrentStep(0)} />,
    },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate();

  // const goBack = () => {
  //   setCurrentStep((prevStep) => {
  //     if (prevStep === 0) {

  //       navigate(-1);
  //       return prevStep; // Keep the current step as 0
  //     } else if (prevStep === 2) {

  //       navigate(-2);
  //       return 0;
  //     } else {

  //       return Math.max(prevStep - 1, 0);
  //     }
  //   });
  // };

  // const goBack = () => {
  //   setCurrentStep((prevStep) => {
  //     if (prevStep === 0) {
  //       navigate(-1);
  //       return prevStep; // Keep the current step as 0
  //     } else if (prevStep === 2) {
  //       // Go back to the first section when on the last section
  //       navigate(-2);
  //       return 0;
  //     } else {
  //       return Math.max(prevStep - 1, 0);
  //     }
  //   });
  // };

  const goBack = () => {
   
    if (currentStep === 0) {
      window.history.back();
    } else {
      // For other steps, navigate to the homepage ("/")
      navigate("/");
    }
  };

  return (
    <Spin spinning={loading} tip="Loading" size="large">
      <div className="container" style={{ marginTop: "100px" }}>
        <button
          onClick={goBack}
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            fontSize: "18px",
            color: "black",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
            gap: "14px",
          }}
        >
          <FontAwesomeIcon icon="fa-arrow-left" /> Back
        </button>
        <Breadcrumb
          separator={<span className="breadcrumb-mobile-separator" />}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {breadcrumbItems.map((item, index) => (
            <Breadcrumb.Item
              key={index}
              className={item.active ? "active" : ""}
            >
              {item.href ? (
                <a href={item.href}>
                  {item.active ? <span>{item.title}</span> : item.title}
                </a>
              ) : (
                <span style={{ color: item.active ? "#FF275E" : "#6b6a6a" }}>
                  {item.title}
                </span>
              )}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div>
          {/* Render content based on the active breadcrumb */}
          {breadcrumbItems[currentStep].section}
        </div>
      </div>
    </Spin>
  );
};

export default BookTicket;
