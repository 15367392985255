import "../../global.css";
import "../privacypolicy/privacy.css";

const Tandc = () => {
  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <h1 className="heading">Terms & Conditions</h1>
      <br></br>

      <div className="cont">
        <h2 className="subheading">Acceptance of Terms</h2>
        <br></br>
        <p className="para">
          By accessing and using this website, you agree to comply with and be
          bound by the following terms and conditions. If you do not agree to
          these terms, please do not use the website.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Ticket Purchase</h2>
        <br></br>
        <div className="subdiv">
          <h3 className="subbheading">Eligibility</h3>
          <p className="para">
            To purchase tickets on this website, you must be at least 18 years
            old or have the consent of a legal guardian.
          </p>
        </div>
<br></br>
        <div className="subdiv">
          <h3 className="subbheading">Access of Information</h3>
          <p className="para">
          You are responsible for providing accurate and up-to-date information during the ticket purchase process. The website is not responsible for any errors resulting from inaccurate information provided by the user.
          </p>
        </div>
      </div>

      <div className="cont">
        <h2 className="subheading">Payment & Pricing</h2>
        <br></br>
        <div className="subdiv">
          <h3 className="subbheading">Payment</h3>
          <p className="para">
          All transactions are processed through a secure payment gateway. The website is not responsible for any unauthorized access to your payment information.
          </p>
        </div>
<br></br>
        <div className="subdiv">
          <h3 className="subbheading">Pricing</h3>
          <p className="para">
          Ticket prices are subject to change without notice. The website reserves the right to modify or update pricing information at any time.
          </p>
        </div>
      </div>

      <div className="cont">
        <h2 className="subheading">Refunds and Exchanges</h2>
        <br></br>
        <div className="subdiv">
          <h3 className="subbheading">Refunds</h3>
          <p className="para">
          Tickets are non-refundable unless the event is canceled. In the case of a canceled event, refunds will be issued in accordance with the event organizer's policies.
          </p>
        </div>
<br></br>
        <div className="subdiv">
          <h3 className="subbheading">Exchanges</h3>
          <p className="para">
          Ticket exchanges may be permitted at the discretion of the event organizer. Additional fees and restrictions may apply.
          </p>
        </div>
      </div>

      <div className="cont">
        <h2 className="subheading">Event Cancellations or Changes</h2>
        <br></br>
        <p className="para">
        The website is not responsible for event cancellations or changes. Users will be notified of any changes, but it is recommended to check the event organizer's policies for the most up-to-date information.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">User Accounts</h2>
        <br></br>
        <div className="subdiv">
          <h3 className="subbheading">Account Security</h3>
          <p className="para">
          Users are responsible for maintaining the security of their accounts. The website is not liable for any unauthorized access to user accounts.
          </p>
        </div>
<br></br>
        <div className="subdiv">
          <h3 className="subbheading">Termination</h3>
          <p className="para">
          The website reserves the right to terminate user accounts for any violation of these terms and conditions.
          </p>
        </div>
      </div>

      <div className="cont">
        <h2 className="subheading">Intellectual Property</h2>
        <br></br>
        <p className="para">
        All content on this website, including but not limited to text, graphics, logos, and images, is the property of the website or its licensors and is protected by copyright laws.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Limitation of Liability</h2>
        <br></br>
        <p className="para">
        The website is not liable for any indirect, incidental, special, or consequential damages resulting from the use of this website or the purchase of tickets.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Governing Law</h2>
        <br></br>
        <p className="para">
        These terms and conditions are governed by the laws of India. Any disputes arising from or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in India.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Changes to Terms</h2>
        <br></br>
        <p className="para">
        The website reserves the right to update or modify these terms and conditions at any time. Users are encouraged to review the terms periodically for changes.
        </p>
      </div>
      
    </div>
  );
};

export default Tandc;
