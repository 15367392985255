import "../../global.css";
import "./privacy.css";

const PrivacyPolicy = () => {
  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <h1 className="heading">Privacy Policy</h1>
      <br></br>

      <div className="cont">
        <h2 className="subheading">Introduction</h2>
        <br></br>
        <p className="para">
          Welcome to WildCard Tickets. This Privacy Policy is designed to help
          you understand how we collect, use, share, and safeguard your personal
          information when you use our website and services.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Information we collect</h2>
        <br></br>

        <div style={{ display: "flex", gap: "50px" }}>
          <div>
            <h3 className="subbheading">Personal Information</h3>
            <br></br>
            <ul>
              <li>Personal InformationName</li>
              <li>Email address</li>
              <li>Billing and shipping address</li>
              <li>Payment information (credit card details)</li>
              <li>Phone number</li>
            </ul>
          </div>

          <div>
            <h3 className="subbheading">
              We also collect non-personal information such as:
            </h3>
            <br></br>
            <ul>
              <li>Browser information</li>
              <li>Device information</li>
              <li>Log data (IP addresses, access times, etc.)</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="cont">
        <h2 className="subheading">How we use this Information</h2>
        <br></br>
        <h3 className="subbheading">
          We use the collected information for the following purposes
        </h3>
        <br></br>
        <ul>
          <li>Process and fulfill your ticket orders</li>
          <li>Communicate with you about your orders and account</li>
          <li>Provide customer support</li>
          <li>Improve our website and services</li>
          <li>Send promotional emails (with opt-out options)</li>
        </ul>
      </div>

      <div className="cont">
        <h2 className="subheading">Information Sharing</h2>
        <br></br>
        <h3 className="subbheading">Information Sharing</h3>
        <br></br>
        <ul>
          <li>Event organizers to facilitate ticket fulfillment</li>
          <li>Payment processors to process transactions</li>
          <li>Service providers for website maintenance and improvement</li>
        </ul>
      </div>

      <p className="alert">
        We do not sell, trade, or rent your personal information to third
        parties for marketing purposes.
      </p>

      <div className="cont">
        <h2 className="subheading">Cookies and Similar Technologies</h2>
        <br></br>
        <p className="para">
          We use cookies and similar technologies to enhance your experience on
          our website. You can control cookies through your browser settings.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Security</h2>
        <br></br>
        <p className="para">
          We implement reasonable security measures to protect your personal
          information. However, no method of transmission over the internet or
          electronic storage is completely secure.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Your Choices</h2>
        <br></br>
        <h3 className="subbheading">You can:</h3>
        <br></br>
        <ul>
          <li>Review and update your account information</li>
          <li>Opt-out of promotional emails</li>
          <li>Control cookie settings</li>
        </ul>
      </div>

      <div className="cont">
        <h2 className="subheading">Children's Privacy</h2>
        <br></br>
        <p className="para">
          Our website is not directed at individuals under the age of 13. We do
          not knowingly collect personal information from children.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Changes to This Privacy Policy</h2>
        <br></br>
        <p className="para">
          We may update this Privacy Policy from time to time. The updated
          version will be posted on our website with the effective date.
        </p>
      </div>

      <div className="cont">
        <h2 className="subheading">Contact Us</h2>
        <br></br>
        <p className="para">
          If you have any questions or concerns about our Privacy Policy, please
          contact us at [contact@email.com].
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
