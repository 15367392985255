import "../../global.css"

const NotFound=()=>{
    return(
        <div className="container" style={{marginTop:'100px'}}>
            <h1 className="text-4xl">page not found</h1>
        </div>
    )
}

export default NotFound