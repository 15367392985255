import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slice'
import seatPricereducer from './seatPriceslice'
import sidedata from './sideData'
import { persistReducer,persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const persistConfig={
  key:'root',
  storage,
}

const persistedReducer=persistReducer(persistConfig,userReducer)

export const store = configureStore({
  reducer: {
    userData:persistedReducer,
    seatPricesData: seatPricereducer,
    sideData:sidedata
  },
})

export const persistor =persistStore(store)