import icon1 from '../../assets/images/icon1.png'
import styles from './styles.module.css'

const ListCard = ({ iconSrc, heading, content }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.iconBack}>
        <img alt="icon" src={iconSrc}/>
      </div>
      <div className={styles.contentOfCard}>
        <div>
          <p className={styles.heading}>{heading}</p>
        </div>

        <div>
          <p className={styles.content}>{content}</p>
        </div>
      </div>
    </div>
  );
};

export default ListCard;
