import React from 'react';
import { Image } from 'antd';
import './gallery.css'

import "../../global.css";
import ev from "../../assets/images/WhatsApp Image 2023-11-13 at 14.02.58_618a1eff.jpg";
import ev2 from "../../assets/images/IMG_8517.jpg";
import ev3 from "../../assets/images/IMG_8518.jpg";
import ev4 from "../../assets/images/Linked.jpg";

const Gallery = () => {
  const images = [ev, ev2, ev3, ev4];

  return (
    <div className="container" style={{ marginTop: "100px",marginBottom:'270px' }}>
      <div>
        <h1 className="heading">Tennis Tournament - 2019</h1>
        <br></br>
        <br></br>
        <Image.PreviewGroup>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {images.map((image, index) => (
              <div key={index} className="w-full h-auto" >
                <Image
                  src={image}
                  alt={`Event ${index + 1}`}
                  // className="w-full h-full object-cover"
                  style={{height:'200px',width:'400px'}}
                />
              </div>
            ))}
          </div>
        </Image.PreviewGroup>
      </div>
    </div>
  );
};

export default Gallery;
