import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      
      navigate('/confirmed');
    }, 3000);

    return () => {
      // Clear the timer in case the component unmounts before the timeout
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      Payment Successful
    </div>
  );
}

export default PaymentSuccess;
