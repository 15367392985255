import React from "react";
import { useSelector } from "react-redux";
import { useState,useEffect } from "react";
import "./coll.css";
import { Card, Divider, Row, Col, Empty, Collapse, Button,Spin } from "antd";
import Ticket from "../../components/ticket";
import { bookTheTicketbyid } from "../../requests";

const { Panel } = Collapse;

const Bookings = () => {
  const [loading, setLoading] = useState(false);
  const normalid = useSelector(
    (state) => state?.userData?.user?.user?.userID);
    console.log(normalid)
    const googleid = useSelector((state) => state?.userData?.user?.userID);
    const userIdToUse = normalid || googleid;
    
    const [detail,setDetails]=useState([])

    const getbyid = async () => {
      try {
        setLoading(true)
        const resp = await bookTheTicketbyid({ userId: userIdToUse });
        // console.log(userIdToUse)
        setDetails(resp.data.data);
        console.log(resp.data.data,"+++++++++++++++++++++++");
      } catch (err) {
        console.log(err);
      }finally{
        setLoading(false)
      }
    };
    
    useEffect(() => {
      if (!detail.length) {
        getbyid();
      }
    }, [userIdToUse]);


    console.log(detail)
  // useEffect(() => {
  //   if (data) {
  //     setDetails(data);
  //   } else if (googledata) {
  //     setDetails(googledata);
  //   }
  // }, [data, googledata]);

  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const extractMonth = (dateString) => {
    const options = { month: 'short' };
    const date = new Date(dateString);
    const monthInWords = date.toLocaleDateString('en-US', options);
    return monthInWords.toUpperCase();
  };
  
  const extractDate = (dateString) => {
    const options = { day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };

  const eventDateObject = new Date(detail.date);

  const formattedEventDate = eventDateObject.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });




  return (
    <div
      className="container"
      style={{ marginTop: "100px", marginBottom: "100px" }}
    >
      <Spin spinning={loading} fullscreen/>
     {detail?.length > 0 ? (
  <div className="viewArrowRem">
    <Collapse accordion bordered={false} style={{ width: "100%",display:'flex',flexDirection:'column',gap:'15px'}} >
      {detail.map((item) => (
        <Panel
          key={item.bookingId}
          header={
            <div className="outerouteralignment">
            <div className="headerofDetails">
              <div style={{ textAlign: "center" }}>
                <p className="month">{extractMonth(item.date)}</p>
                <p className="datebook">{extractDate(item.date)}</p>
              </div>
              <Divider type="vertical" style={{ height: "3rem" }} className="diviDer"/>
              <div className="seatsalignment">
                <div>
                  <p className="titlebook">{item.eventName}</p>
                  <p className="addressbook">{item.venue}, {item.city}</p>
                </div>
                {item.seats?.map((seat, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: "400",
                      fontFamily: "Courier Prime",
                    }}
                  >
                    {seat.label}
                  </p>
                  <p
                    style={{
                      fontSize: "34px",
                      fontWeight: "700",
                      fontFamily: "Courier Prime",
                    }}
                  >
                    {seat.value}
                  </p>
                </div>
              ))}
              
              </div>
            </div>

            <div>
            <Button onClick={toggleCollapse} className="viewTicketButton">
              {/* {isCollapsed ? "View Ticket" : "Fold ticket"} */}
              View Ticket
            </Button>
            </div>
            </div>
          }
          // extra={
            
          // }
          style={{ border: "1px solid black", borderRadius: "10px",  }}
        >
          <Ticket
            name={item.eventName}
            time={item.time}
            qrImage={item.qrCode}
            seats={item.seats}
            date={new Date(item.date).toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
      year: "numeric",
    })}
            venue={item.venue}
            city={item.city}
          />
        </Panel>
      ))}
    </Collapse>
  </div>
) : (
  <Empty description="No booked tickets yet" />
)}

    </div>
  );
};

export default Bookings;
