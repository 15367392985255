import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined } from '@ant-design/icons';
import '../../global.css'
import { Menu,Tabs } from 'antd';
import { Link } from 'react-router-dom';
import Userprofile from '../../containers/profileuser'; 
import Bookings from '../../containers/bookings'; 

const AccountMenu = () => {
    const [activeTab, setActiveTab] = useState("1");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const tabContent={
    1:(<Userprofile/>),
    2:(<Bookings/>)
  }


  
 

  return (
    <div className='container' style={{marginTop:'100px'}}>
    <Tabs
      defaultActiveKey={activeTab}
      type="card"
      size="small"
      onChange={handleTabChange}
    >
      <Tabs.TabPane tab="Profile" key="1">
        {tabContent[activeTab]}
      </Tabs.TabPane>
      <Tabs.TabPane tab="Bookings" key="2">
        {tabContent[activeTab]}
      </Tabs.TabPane>
      
    </Tabs>
  </div>
  );
};

export default AccountMenu;
