import { Spin, Skeleton, Empty } from "antd";
import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import React from "react";
import "../../global.css";
import { filterTickets } from "../../requests";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

const EventCard = lazy(() => import("../../components/EventCard"));

const Categorydetail = () => {
  const { category } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [filteredApiTickets, setfiltered] = useState([]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const goBack = () => {
    navigate(-1);
  };

  const city = useSelector((state) => state?.userData?.city);

  const displayCity = city ? city.toUpperCase() : "DELHI";
  const capitalizedCategory = capitalizeFirstLetter(category);

  // const filteredEvents = eventList.filter(
  //   (event) => event.genre.toLowerCase() === category.toLowerCase()
  // );

  const SkeletonFallback = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7">
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );

  const EventCardGrid = ({ events }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7">
      {events.map((event) => (
        <EventCard key={event.id} event={event} />
      ))}
    </div>
  );

  const ticketFiltered = async () => {
    try {
      const filterParams = { eventCategory: category };
      if (city) {
        filterParams.city = city;
      }

      const resp = await filterTickets(filterParams);

      setLoading(true);
      const filteredTickets = resp.data.filteredTickets.filter(
        (ticket) => ticket.status === 'publish'
      );
      setfiltered(filteredTickets);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  console.log(filteredApiTickets, "---------------------");

  useEffect(() => {
    ticketFiltered();
  }, [city]);

  return (
    <div className="container mx-auto p-4" style={{ marginTop: "100px" }}>
      <button className="mb-10" onClick={goBack}>
        <FontAwesomeIcon icon="fa-arrow-left" /> Back
      </button>

      <div>
        <h1 className="text-3xl font-bold mb-8">
          {capitalizedCategory} in{" "}
          <span style={{ color: "#ff275e" }}>{`"${displayCity}"`}</span>
        </h1>

        <Suspense fallback={<SkeletonFallback />}>
          {!loading ? (
            filteredApiTickets.length > 0 ? (
              <EventCardGrid events={filteredApiTickets} />
            ) : (
              <Empty
                description={
                  <p className="text-xl text-gray-500">
                    Sorry, no events for this category.
                  </p>
                }
              />
            )
          ) : (
            <SkeletonFallback />
          )}
        </Suspense>
      </div>
    </div>
  );
};

export default Categorydetail;
