import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider } from "antd";
import logo from '../../assets/images/logo.svg'
import styles from "../../containers/confirm/styles.module.css"; 

const Ticket = ({ name, time, venue,city, qrImage, seats, date }) => {
  return (
    <div
      className="pt-30"
      style={{ display: "flex", justifyContent: "center",color:'white' }}
    >
      <div className={styles.ticketSection}>
        {/* <h2 style={{ color: "green" }}>
          <FontAwesomeIcon icon="fa-circle-check" /> Ticket Confirmed
        </h2> */}
        <br></br>
        <div className={styles.ticket}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0 10px",
              justifyContent:'center',
              // background:'black'
            }}
          >
            <img
              src={qrImage}
              alt="qr"
              style={{ height: "200px", width: "200px",marginBottom:'10px' }}
            />
            <img  src={logo}/>
            <br></br>
            
          </div>
          <Divider
            type="vertical"
            dashed
            style={{
              height: "20em",
              borderColor: "#a1a1a1",
              borderWidth: "2px",
            }}
            className={styles.verticalDiv}
          />
          <Divider
            // type="vertical"
            dashed
            // style={{ height: "20em", borderColor: "#a1a1a1", borderWidth: "2px" }}
            className={styles.horizonDiv}
          />
          <div className={styles.ticketDetails}>
            <p
              style={{
                fontSize: "60px",
                fontWeight: "700",
                textTransform: "uppercase",
                fontFamily: "Courier Prime",
              }}
            >
              {name}
            </p>
            <Divider className={styles.horizontal}/>
            <div
              style={{ display: "flex", flexDirection: "column" }}
            >
              <p
                style={{
                  fontSize: "23px",
                  fontWeight: "700",
                  fontFamily: "Courier Prime",
                }}
              >
                {date} <Divider type="vertical" />
                {time}
              </p>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  fontFamily: "Courier Prime",
                }}
              >
                {venue},{city}
              </p>
            </div>
            <Divider className={styles.horizontal}/>
              <p  style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  fontFamily: "Courier Prime",
                }}>CATEGORY</p>
            <div className={styles.seatPos}>
              {seats?.map((seat, index) => (
                <div
                  key={index}
                  style={{ display: "flex",flexDirection:'column', alignItems: "center" }}
                >
                  <p
                    style={{
                      fontSize: "60px",
                      fontWeight: "700",
                      fontFamily: "Courier Prime",
                    }}
                  >
                    {seat.label}
                  </p>
                  <p
                    style={{
                      fontSize: "34px",
                      fontWeight: "700",
                      fontFamily: "Courier Prime",
                    }}
                  >
                    X{seat.value}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
