import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Modalpop from "../../components/modal";
import loginImg from "../../assets/images/login.jpg";
import googleLogo from "../../assets/images/googlelogo.png";
import { sendOtp, verify } from "../../requests";
import { Divider, notification, Typography, Spin, Modal, Button } from "antd";
import { LoadingOutlined,CloseOutlined  } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../slice";
import { googleLogin } from "../../requests";
import loginpic from "../../assets/images/loginpic.png";
import logo from "../../assets/images/logonew.png";

const LoginPopup = ({ isOpen, onClose }) => {
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);

  const [Otp, setOTP] = useState("");
  const { Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const userPresent = useSelector((state) => state?.userData?.user);
  const forcePhone = useSelector((state) => state?.userData?.user?.phoneNumber);

  // console.log(forcePhone)
  // const showModal = isUserLoggedIn && !forcePhone;
  // useEffect(() => {
  //   const loggedIn = !!userPresent;
  //   setIsUserLoggedIn(loggedIn);
  // }, [userPresent]);

  // console.log(isUserLoggedIn,"dbsjkhfbdjhfvbfd");

  const countryCode = "91";

  const handleModalOk = () => {
    // Handle OK button click if needed
  };

  const handleModalCancel = () => {
    // Handle cancel button click if needed
  };

  const handlePhoneNumberChange = (e) => {
    const enteredNumber = e.target.value;
    setPhoneNumber(countryCode + enteredNumber);
  };

  const handleVerify = async () => {
    if (PhoneNumber.length !== 12) {
      notification.error({
        message: "Invalid phone number",
        description: "Please enter a valid phone number with 10 digits.",
      });
      return;
    }
    try {
      setLoading(true);
      const resp = await sendOtp({ phoneNumber: PhoneNumber });

      notification.success({
        message: "OTP sent!",
        // description: 'You have successfully verified.',
      });

      console.log(resp);
      setShowOTPInput(true);
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const resp = await verify({ phoneNumber: PhoneNumber, otp: Otp });
      const data = resp.data.data;
      // console.log(data, "_______________==========");
      dispatch(setUser(data));

      console.log("Redux State after dispatch:", userData);
      notification.success({
        message: "User Verified!",
      });

      window.location.href = data.redirectUrl;

      setPhoneNumber("");
      setOTP("");
      onClose();
    } catch (err) {
      notification.error({
        message: "Something went wrong",
      });
      console.log(err);
    } finally {
      setShowOTPInput(false);
      setLoading(false);
    }
  };

  const handleResend = () => {
    setShowOTPInput(false);
  };

  const loginGoogle = async () => {
    window.open(
      "https://demo.wildcardtickets.com/googleAuth/auth/google/callback",
      "_self"
    );
  };

  // const loginGoogle = async () => {
  //   window.open(
  //     "http://localhost:5000/googleAuth/auth/google/callback",
  //     "_self"
  //   );
  // };

  return (
    <Modalpop isOpen={isOpen} onClose={onClose}>
      <div className="relative flex flex-col md:flex-row justify-between items-center">
      <button
          className="absolute top-2 right-2 bg-transparent border-none text-black text-xl"
          onClick={onClose}
        >
          <CloseOutlined />
      
        </button>
        <div className={styles.imageResponsive}>
          <img src={loginpic} alt="Logo" className={styles.image} />
        </div>
        <div className={styles.loginContainer}>
          <img alt="logo" src={logo} style={{ width: "130px" }} />
          <h1 className="text-3xl font-bold">Welcome</h1>
          <div className="w-full">
            <button
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={loginGoogle}
              className={styles.loginWithGoogle}
            >
              <img src={googleLogo} className={styles.googleLogo} />
              Login With Google
            </button>
          </div>
          <Divider style={{ color: "#d3d3d3", width: "2px" }}>
            <p
              style={{ fontSize: "12px", fontWeight: "400", color: "#454545" }}
            >
              or login using
            </p>
          </Divider>
          {!showOTPInput && (
            <div className="w-full flex flex-col gap-3">
              <p>Enter Mobile Number</p>
              <div className="flex items-center">
                <span className="mr-2">{countryCode}</span>
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={PhoneNumber.replace(countryCode, "")}
                  onChange={handlePhoneNumberChange}
                  className="border p-2 w-full rounded"
                  style={{ background: "whitesmoke" }}
                />
              </div>
            </div>
          )}

          {!showOTPInput && (
            <button
              onClick={handleVerify}
              className="text-white px-4 py-2 rounded w-full font-bold"
              style={{ background: "#ff275e" }}
            >
              Log in
            </button>
          )}

          {showOTPInput && (
            <div className="w-full flex flex-col gap-2 items-center">
              <p className="font-bold">OTP Verification</p>
              <p>
                <span className="text-gray-500">Enter OTP sent to </span>
                <span className="font-bold">{PhoneNumber}</span>
              </p>
              <input
                type="text"
                placeholder="Enter OTP"
                value={Otp}
                onChange={(e) => setOTP(e.target.value)}
                className="border p-2 mt-1 mb-1 w-full rounded"
                style={{ background: "whitesmoke" }}
              />
            </div>
          )}

          {showOTPInput && (
            <button
              onClick={handleResend}
              className="text-red-500 rounded w-full "
            >
              Resend OTP?
            </button>
          )}

          {showOTPInput && (
            <button
              onClick={handleLogin}
              className=" text-white px-4 py-2 rounded w-full font-bold"
              style={{ background: "#ff275e" }}
            >
              Verify and Proceed
            </button>
          )}
        </div>
      </div>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
        fullscreen
      />
    </Modalpop>
  );
};

export default LoginPopup;
