import styles from "./styles.module.css";
import "../../global.css";
import { LinkedinOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebook, faInstagram, faTwitter } from '@fontawesome/free-brands-svg-icons'
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-black">
      <div className="bg-black text-white p-6 flex flex-col md:flex-row justify-start gap-20 container-head">
        <div className="flex flex-col gap-7 ">
          {/* <img alt="logo"/> */}
          <div>
            <img alt="logo" src={logo} />
          </div>
          <p className="text-sm" style={{ maxWidth: "230px" }}>
            Discover, Experience, and Attend Your Favorite Events with WildCard
            Tickets. Explore a world of entertainment, secure your tickets, and
            make unforgettable memories with us."
          </p>
          
        </div>

        <div className={styles.navFooter}>
          <p className="text-lg font-bold">Navigation</p>
          <Link to="/">
            <button className="text-sm">Home</button>
          </Link>
          <Link to="/events">
            <button className="text-sm">Events</button>
          </Link>
          <Link to="/gallery">
            <button className="text-sm">News & Media</button>
          </Link>
          <Link to="/listyourevents">
            <button className="text-sm">List your event</button>
          </Link>
          <Link to="/career">
            <button className="text-sm">Career</button>
          </Link>
        </div>

        <div className={styles.navFooter}>
          <button className="text-lg font-bold">Support</button>
          <Link to="/contactus">
            <button className="text-sm">Contact us</button>
          </Link>
          <Link to="aboutus">
            <button className="text-sm">About us</button>
          </Link>
          <Link to="/privacypolicy">
            <button className="text-sm">Privacy Policy</button>
          </Link>
          <Link to="/termsandcondition">
            <button className="text-sm">Terms and Conditions</button>
          </Link>
        </div>
      </div>
      <hr></hr>
      <div className="container-head">
      <div className={styles.belowLine}>
      <div style={{color:'#fff'}} className="text-sm mt-1 pb-1">All rights reserved © WildCard Ticket Services Pvt Ltd</div>

      <div className={styles.socialLink}>
            <h5 className="text-sm font-bold text-white">Follow us:</h5>
            <a
              href="https://www.facebook.com/WildcardTickets?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} style={{color:'white'}}/>
            </a>
            <a
              href="https://instagram.com/wildcard.tickets?igshid=YTQwZjQ0NmI0OA=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} style={{color:'white'}}/>
            </a>
            <FontAwesomeIcon icon={faTwitter} style={{color:'white'}}/>
            <a href="https://www.linkedin.com/company/wildcardtickets/">
              <LinkedinOutlined style={{ fontSize: "17px", color: "white" }} />
            </a>
          </div>
      </div>
      </div>
    </div>
  );
};

export default Footer;
