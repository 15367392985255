import "../../global.css";
import contact from "./contact.module.css";
import { Card, Modal, Button, Input, Form,notification } from "antd";
import contact1 from "../../assets/images/contact1.png";
import contact2 from "../../assets/images/contact2.png";
import contact3 from "../../assets/images/contact3.png";
import { sentQuery } from "../../requests";
import { useState } from "react";

const Contactus = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = async(values) => {
    try{
    const resp =await sentQuery(values)
    console.log(resp)
    notification.success({
      message: "Query Submitted",
      description: "Your query has been submitted successfully.",
    });
    form.resetFields();
    setIsModalVisible(false); 
    }catch(err){
      console.log(err)
      notification.error({
        message: "Submission Error",
        description: "There was an error submitting your query. Please try again.",
      });
      
    }
  };
  return (
    <>
      <div
        className="container"
        style={{ marginTop: "100px", marginBottom: "400px" }}
      >
        <div className={contact.test}>
          <h1 className="heading">Contact Us</h1>
          <br></br>
          <div className={contact.cards}>
            <Card
              hoverable
              style={{
                // width: 400,
                // height: 440,
                background: "#f0f0f0",
              }}
              className={contact.cardDimensions}
              cover={
                <img className={contact.image} alt="example" src={contact1} />
              }
            >
              <div className={contact.content}>
                <div className={contact.head}>
                  <h2>Online Enquiry</h2>
                  <h3>We’ll respond to you in 3 working days</h3>
                </div>
                <div className={contact.btn}>
                  <button className={contact.btun} onClick={showModal}>
                    Send a Message
                  </button>
                </div>
              </div>
            </Card>

            <Card
              hoverable
              style={{
                // width: 400,
                // height: 440,
                background: "#f0f0f0",
              }}
              className={contact.cardDimensions}
              cover={
                <img className={contact.image} alt="example" src={contact2} />
              }
            >
              <div className={contact.content}>
                <div className={contact.head}>
                  <h2>Call Us</h2>
                  <div>
                  <h3>+91 6209000050</h3>
                  <h3>+91 6209000060</h3>
                  </div>
                  <div
                    style={{
                      marginTop: "15px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <h4>
                      Monday to Saturday{" "}
                      <span className={contact.breakColor}>
                        10:00am - 6:00pm
                      </span>
                    </h4>
                    <h4>
                      Sunday :{" "}
                      <span className={contact.breakColor}>
                        {" "}
                        10:00am - 2:00pm
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </Card>

            <Card
              hoverable
              style={{
                // width: 400,
                // height: 440,
                background: "#f0f0f0",
              }}
              className={contact.cardDimensions}
              cover={
                <img className={contact.image} alt="example" src={contact3} />
              }
            >
              <div className={contact.content}>
                <div className={contact.head}>
                  <h2>Write to us</h2>
                  {/* <h3>We’ll respond to you in 3 working days</h3> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    marginTop: "30px",
                  }}
                >
                  <h4>90/C</h4>
                  <h4>Ashok Nagar, Ranchi, Jharkhand</h4>
                  <h4>PIN: 834002</h4>
                  <h4>Email : contact@wildcardtickets.com</h4>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Modal
        title="Send a Message"
        visible={isModalVisible}
        onCancel={handleCancel}
      
        footer={null}
      >
        <Form onFinish={onFinish} layout="vertical" form={form}>
          <Form.Item
            label="Your Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Your Email"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Query"
            name="query"
            rules={[
              {
                required: true,
                message: "Please input your message!",
              },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item>
      <Button type="primary" htmlType="submit" style={{ background: '#ff275e' }}>
        Send
      </Button>
    </Form.Item>
          
        </Form>
      </Modal>
    </>
  );
};

export default Contactus;
