import "./App.css";
import './input.css'
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/routes";
import BasicLayout from "./layout";
import { library } from '@fortawesome/fontawesome-svg-core';
import { ConfigProvider } from 'antd';

import {faTicket,faSortDown, faMusic,faMasksTheater ,faBasketball,faPeopleRobbery,faFilm,faBurger,faPersonHiking, faArrowLeft, faBars, faCircleCheck, faMagnifyingGlass, faLocationCrosshairs, faCalendarDays, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faArrowDown,faTicket,faLocationCrosshairs,faCalendarDays,faMagnifyingGlass,faSortDown,faBars,faCircleCheck,faMusic,faMasksTheater,faBasketball,faPeopleRobbery,faFilm,faBurger,faPersonHiking,faArrowLeft);


function App() {
  return (
    <ConfigProvider
    theme={{
      token:{
        colorPrimary:'#FF275E'
      } 
    }}
  >
    <BrowserRouter>
      <BasicLayout>
        <Routes />
      </BasicLayout>
    </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
