import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cities: null,
  categories: null,
};

const sideDataslice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setCities: (state, action) => {
      state.cities = [...action.payload];
    },
    setCategories: (state, action) => {
      state.categories = [...action.payload];
    },
    clearData: (state) => {
      state.cities = null;
      state.categories = null;
    },
  },
});

export const { setCities, setCategories, clearData } = sideDataslice.actions;

export default sideDataslice.reducer;
