import "../../global.css";
import icon1 from '../../assets/images/icon1.png';
import tap from '../../assets/images/tap.png'
import cash from '../../assets/images/cash.png'
import video from '../../assets/images/video.png'
import check from '../../assets/images/check.png'
import comm from '../../assets/images/comm.png'
import { useState } from "react";
import { Modal, Form, Input, Button, DatePicker, notification } from "antd";
import styles from "./styles.module.css";
import "./ant.css";
import { format } from "date-fns";
import "date-fns/locale";
import ListCard from "../../components/listcard";
import moment from "moment";
import { sentListyourevnt } from "../../requests";

const ListYourevents = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { RangePicker } = DatePicker;
  const openModal = () => {
    setModalVisible(true);
  };

  const handleFormSubmit = async (values) => {
    try {
      const formattedDate = formatDate(values.eventDate);

      const updatedValues = {
        ...values,
        formattedDate: formattedDate,
      };
      const resp = await sentListyourevnt(updatedValues);
      console.log(resp);
      notification.success({
        message: "Form Submission Successful",
        description: "Your event details have been submitted successfully.",
      });

      console.log("Form values:", updatedValues);

      setModalVisible(false); // Close the modal after submission
    } catch (err) {
      notification.error({
        message: "Form Submission Failed",
        description:
          "There was an error while submitting your event details. Please try again.",
      });
      console.log(err);
    }
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const formatDate = (date) => {
    // Convert the moment object to a JavaScript Date object
    const jsDate = moment(date).toDate();

    const { year, month, day } = {
      year: jsDate.getFullYear(),
      month: jsDate.getMonth() + 1, // Months are zero-indexed
      day: jsDate.getDate(),
    };

    return format(new Date(year, month - 1, day), "yyyy-MM-dd");
  };

  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <h1 className="heading">List Your Events</h1>
      <br></br>
      <div className={styles.pinkCard}>
        <div className={styles.firstDiv}>
          <p className={styles.headingPinkCard}>On Ground Events</p>
          <div className={styles.detailSec}>
            <p className={styles.subHeading}>Convenience Fee</p>
            <p className={styles.para}>
              Convenience Fee: 2% of the ticket price will be added as a
              convenience fee for all transactions.
            </p>
          </div>

          <div className={styles.detailSec}>
            <p className={styles.subHeading}>Online Transaction</p>
            <p className={styles.para}>
              The convenience fee applies to all online ticket transactions
              through the Wildcard Tickets platform.
            </p>
          </div>
          <div>
            <button className={styles.enterDetails} onClick={openModal}>
              Enter Details
            </button>
          </div>
        </div>

        <div className={styles.divSecond}>
          <div className={styles.middle}>
            <p className={styles.startingfromText}>Starting from </p>
            <br></br>
            <p className={styles.percent}>
              2% <span className={styles.convi}>Convenience Fee</span>
            </p>
          </div>

          <div className={styles.regularSection}>
            <p className={styles.regularText}>REGULAR</p>
          </div>
        </div>
      </div>

      <p className={styles.featuresHeading}>Features</p>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-20 mt-20 mb-40">
      <div class="col-span-1 flex justify-center">
        <ListCard
        iconSrc={tap}
        heading="Flexibility"
        content="List and manage on-ground and digital experiences through a single dashboard."
      />
        </div>
        <div class="col-span-1 flex justify-center">
        <ListCard
        iconSrc={icon1}
        heading="Customer Data"
        content="Take an export with all attendee data. Collect additional details using customizable forms."
      />
        </div>
        <div class="col-span-1 flex justify-center">
        <ListCard
        iconSrc={comm}
        heading="Communication"
        content="Talk to your ticket buyers, send them instructions or collect feedback, via WhatsApp/Email."
      />
        </div>
        <div class="col-span-1 flex justify-center">
        <ListCard
        iconSrc={check}
        heading="Safety Measures"
        content="Specify safety measures you will have at your event keeping your customer at ease."
      />
        </div>
        <div class="col-span-1 flex justify-center">
        <ListCard
        iconSrc={video}
        heading="Interaction & Media"
        content="Engage your attendees via video, chat and Q&A. Use music, image and more for event packaging."
      />
        </div>
        <div class="col-span-1 flex justify-center">
        <ListCard
        iconSrc={cash}
        heading="Automated Payouts"
        content="Onboard your brand and collect payouts within 7 days after an event."
      />
        </div>
      </div>

      <Modal
        title="Send a Message"
        visible={modalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form name="eventForm" onFinish={handleFormSubmit} layout="vertical">
          <div className={styles.formItems}>
            <Form.Item
              name="yourName"
              label="Your Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="companyName"
              label="Company Name"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={styles.formItems}>
            <Form.Item name="phone" label="Phone" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, type: "email" }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={styles.formItems}>
            <Form.Item
              name="venueLocation"
              label="Venue Location"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="eventDate"
              label="Event Date"
              rules={[{ required: true }]}
            >
              <DatePicker format="YYYY-MM-DD " />
            </Form.Item>
          </div>
          <Form.Item
            name="eventDetails"
            label="Event Details"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.submitbtn}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ListYourevents;
