import { Spin } from "antd";
import { bookTheTicket } from "../../requests";
import {useState,useEffect,useRef} from 'react'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import '../../global.css'
import styles from './styles.module.css'

const GenerateTicket=()=>{
    const navigate=useNavigate()
    const [detail, setDetail] = useState([]);
    const ticketID = localStorage.getItem("ticketId");
  const finalSeats = JSON.parse(localStorage.getItem("finalseats"));
  const userid = useSelector((state) => state?.userData?.user?.user?._id);
  const googleUser = useSelector((state) => state?.userData?.user?._id);
  const totalAmount = parseFloat(localStorage.getItem('totalAmount'));


  const requestBody = {
    ticketId: ticketID,
    userId: userid || googleUser,
    seats: finalSeats,
    totalAmountpaid: totalAmount
  };

  const isEffectExecuted = useRef(false);

  const ticketBook = async () => {
    try {
      // setrefresh(true);

      const resp = await bookTheTicket(requestBody);

      console.log(
        resp.data.bookedticket,
        "here is the response afetr confirmation"
      );
      setDetail(resp.data.bookedticket);
      localStorage.setItem("bookedTicketDetail", JSON.stringify(resp.data.bookedticket));

      if (resp.data.redirectUrl) {
        navigate(resp.data.redirectUrl);
      }
      
    } catch (err) {
      console.log(err);
    }
   
  };

  useEffect(() => {
    if (!isEffectExecuted.current) {
      ticketBook();
      isEffectExecuted.current = true;
    }
  }, []);

    return(
        <div className="container" style={{marginTop:"150px",marginBottom:'150px'}}>
          <div className={styles.generating}>
        <p className={styles.textOfwait}>Please wait, we are generating your ticket...</p>
        <div><Spin /></div>
        </div>
        </div>
    )
}

export default GenerateTicket