import axios from 'axios'


const BASE_URL = process.env.REACT_APP_BASE_URL;

const apiKey = process.env.REACT_APP_API_KEY;




const sendOtp=async(value)=>{
    const response=await axios.post(`${BASE_URL}/otp/sendotp`,value,{
      headers: {
        'x-api-key': apiKey,
      },
    })
    return response
}

const verify=async(values)=>{
    const response=await axios.post(`${BASE_URL}/otp/verifyotp`,values,{
      headers: {
        'x-api-key': apiKey,
      },
    })
    return response
}

const tickets=async()=>{
    const response=await axios.get(`${BASE_URL}/ticket/getalltickets`)
    return response
}

const recenteventsData=async()=>{
  const response=await axios.get(`${BASE_URL}/ticket/recentevents`)
  return response
}

const ticketDetail=async(id)=>{
    const response=await axios.get(`${BASE_URL}/ticket/${id}`)
    return response
}

const filterTickets=async(values)=>{
    const response=await axios.post(`${BASE_URL}/ticket/filtertickets`,values,{
        headers: {
          'x-api-key': apiKey,
        },
      })
    return response
}

const addDetails=async(values,phone)=>{
    const response=await axios.put(`${BASE_URL}/profile/adddetails/${phone}`,values,{
      headers: {
        'x-api-key': apiKey,
      },
    })
    return response
}

const getAlldetails=async(value)=>{
    const response=await axios.post(`${BASE_URL}/profile/getdetails`,value,{
      headers: {
        'x-api-key': apiKey,
      },
    })
    return response
}

const googleLogin=async()=>{
    const response=await axios.get(`${BASE_URL}/googleAuth/login/success`,{withCredentials: true,})
    return response
}

const googleLogout=async()=>{
    const response=await axios.get(`${BASE_URL}/googleAuth/logout`,{withCredentials: true,})
    return response
}

const googleUserDetailsUpdate=async(values,id)=>{
    const response=await axios.put(`${BASE_URL}/profile/adddetails/guser/${id}`,values,{
      headers: {
        'x-api-key': apiKey,
      },
    })
    return response
}

const getcategory=async()=>{
    const response=await axios.get(`${BASE_URL}/category/categories`)
    return response
  }

  const getcities=async()=>{
    const response=await axios.get(`${BASE_URL}/city/get`)
    return response
  }

  const globalSearch=async(searchQuery)=>{
    const response=await axios.get(`${BASE_URL}/ticket/search?query=${searchQuery}`)
    return response
  }

  const citySearch=async(searchQuery)=>{
    const response=await axios.get(`${BASE_URL}/city/search?q=${searchQuery}`)
    return response
  }

  const sentQuery=async(values)=>{
    const response=await axios.post(`${BASE_URL}/contactus/storequery`,values,{
      headers: {
        'x-api-key': apiKey,
      },
    })
    return response
  }


  const sentListyourevnt=async(values)=>{
    const response=await axios.post(`${BASE_URL}/event/listyourevents`,values,{
      headers: {
        'x-api-key': apiKey,
      },
    })
    return response
  }

  const bookTheTicket = async (values) => {
    const response = await axios.post(`${BASE_URL}/bookticket/wildcard`, values, {
      headers: {
        'x-api-key': apiKey,
      },
    });
    return response;
  };

  const bookTheTicketbyid = async (values) => {
    const response = await axios.post(`${BASE_URL}/bookticket/filterByUserId`, values, {
      headers: {
        'x-api-key': apiKey,
      },
    });
    return response;
  };

  const uploadResume = async (values) => {
    const response = await axios.post(`${BASE_URL}/candidates/add`, values, {
      // headers: {
      //   'x-api-key': apiKey,
      // },
    });
    return response;
  };

  export const Getpartners=async()=>{
    const response=await axios.get(`${BASE_URL}/partners`)
    return response
  }


  

export{citySearch,recenteventsData,uploadResume,bookTheTicketbyid,bookTheTicket,sentListyourevnt,sentQuery,globalSearch,getcategory,getcities,googleUserDetailsUpdate,sendOtp, verify,tickets,ticketDetail,filterTickets,addDetails,getAlldetails,googleLogin,googleLogout}