import React, { useState } from "react";
import { Form, Input, Button, notification, message,Spin } from "antd";
import styles from "./styles.module.css";
import AWSS3Uploader from "../../components/awss3uploader";
import { uploadResume } from "../../requests";
import { useForm } from "antd/es/form/Form";

const Career = () => {
  const [form] = useForm();
  const [uploadedFileUrl, setUploadedFileUrl] = useState(null);
  const [loading,setLoading]=useState(false);

  const handleUpload = (url) => {
    setUploadedFileUrl(url);
  };
  const onFinish = async (values) => {
    try {
      setLoading(true)
      if (!uploadedFileUrl) {
        message.error("Please upload your resume!");
        return;
      }
      const updatedValues = { ...values, resume: uploadedFileUrl };
      const resp = await uploadResume(updatedValues);
      
      form.resetFields();
      setUploadedFileUrl(null);

      notification.success({
        message: "Submission Successful",
        description: "We appreciate your interest. We will get back to you soon.",
      });
    } catch (err) {
      console.log(err);
      notification.error({
        message: "Submission Failed",
        description: "There was an error submitting your form. Please try again.",
      });
    }finally{
      setLoading(false)
    }
  };

  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <Spin spinning={loading} fullscreen/>
      <div className={styles.layout}>
        <div className={styles.form}>
          <div className=" mb-10">
          <h1 className="mb-4 heading">Explore Your Future: Careers at WildCard Ticket</h1>
          <p className="text-gray-600">Ready to take the next step in your career? Upload your resume and fill out the form below to join our talented team!</p>
          </div>
         <div className={styles.formBorder}>
          <Form name="careerForm" onFinish={onFinish} layout="vertical" form={form}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Qualification"
              name="qualification"
              rules={[
                { required: true, message: "Please enter your qualification!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                { required: true, message: "Please enter your address!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: "Please enter your phone number!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email ID"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="resume"
              label="Upload Resume"
              valuePropName="fileList"
              // rules={[
              //   { required: true, message: "Please upload your resume!" },
              //   {
              //     validator(_, value) {
              //       if (value && value[0] && value[0].url) {
              //         return true;
              //       }
              //       return false;
              //     },
              //     message: "Please wait for the file to finish uploading!",
              //   },
              // ]}
            >
              <AWSS3Uploader onUpload={handleUpload} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.submitButton}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
