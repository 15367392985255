import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import logo from '../../assets/images/neloadlogo.png';
import styles from './styles.module.css'; // Import the CSS module

const Loader = ({ loading }) => {
  return (
    loading && (
      <div className={`fixed inset-0 flex items-center justify-center bg-white z-50 ${styles.loaderContainer}`}>
        <div className={`flex flex-col items-center ${styles.logoContainer}`}>
          {/* Logo with zoom in/out animation */}
          <div className={`animate__animated animate__infinite animate__zoomInOut ${styles.logoAnimation}`}>
            {/* Add your logo or image here */}
            <img src={logo} alt="Logo" className={styles.logo} />
          </div>
<br></br>
          {/* Loading icon with spin animation */}
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      </div>
    )
  );
};

export default Loader;
