import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import AWS from "aws-sdk";
import { Spin } from "antd";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const S3_BUCKET_NAME = "wildcardimages";
const S3_BUCKET_URL = `https://${S3_BUCKET_NAME}.s3.amazonaws.com/`;

const AWSS3Uploader = ({ onUpload }) => {
  const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];
    const fileName = `${Date.now()}_${file.name}`;

    setUploading(true);

    const s3 = new AWS.S3();
    const params = {
      Bucket: S3_BUCKET_NAME,
      Key: fileName,
      Body: file,
    
    };

    try {
      const data = await s3.upload(params).promise();
      const fileUrl = S3_BUCKET_URL + fileName;

      onUpload(fileUrl);
      console.log("File uploaded successfully. URL:", fileUrl);
       setUploadSuccess(true);
    } catch (error) {
      console.error("Error uploading file to S3:", error);
    }
     finally {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here</p>
          ) : (
            <p>Drag & drop a file here, or click to select a file</p>
            )}
            <Spin tip="Uploading..." spinning={uploading} />
      </div>
        {uploadSuccess && (
          <p style={{ color: "green" }}>Resume uploaded successfully!</p>
        )}
      
    </div>
  );
};

const dropzoneStyle = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginTop: "20px",
};

export default AWSS3Uploader;
