import "../../global.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Skeleton } from "antd";
import { Button, Empty} from "antd";
import { useState, useEffect ,useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { filterTickets } from "../../requests";
import styles from "./styles.module.css";
import EventCard from "../../components/EventCard";
const Events = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const city = useSelector((state) => state?.userData?.city);
  const [Events, setEvents] = useState([]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const SkeletonFallback = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-7 mt-10 mb-10">
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );


  const skeletonCard = (
    // <div className={styles.crousgap}>
    <div style={{padding:'10px'}}>
      <Skeleton active />
     </div>
  );

  const genres = ["Concert", "Sports", "Dance", "Comedy","Festivities","Theatre & Art","Adventure","Food"];

  const handleViewMoreClick = (genre) => {
    // Assuming genre is a parameter in your route
    // navigate(`/events/category/${genre.toLowerCase()}`);

    const encodedGenre = encodeURIComponent(genre.toLowerCase());
    
    
    navigate(`/viewmore?category=${encodedGenre}`);
  };

  const memoizedEventsCity = useMemo(() => async () => {
    try {
      setLoading(true)
      const resp = await filterTickets({ city });
      const filteredTickets = resp.data.filteredTickets.filter(
        (ticket) => ticket.status === 'publish'
      );
      setEvents(filteredTickets);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [city]);

  useEffect(() => {
    memoizedEventsCity();
  }, [memoizedEventsCity]);



  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <p className="trendingText">
        Trending Events in{" "}
        <span style={{ color: "#ff275e" }}>{`"${city}"`}</span>
      </p>

      {!loading && Events.length === 0 && <div className="mt-20 mb-20">  <Empty description="No Events Found" /></div>}
      
      {loading ? (
        <SkeletonFallback/>
      ) : (
        genres.map((genre) => {
          const genreEvents = Events.filter(
            (event) => event.eventCategory.toLowerCase() === genre.toLowerCase()
          );

          if (genreEvents.length > 0) {
            return (
              <div key={genre}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingTop: "30px",
                    alignItems:'center'
                  }}
                >
                  <p className="pl-3" style={{color:'#555', fontSize:'30px',fontWeight:'500'}}>{genre}</p>
                  <Button
                    type="link"
                    className="viewmore"
                    onClick={() => handleViewMoreClick(genre)}
                  >
                    View More
                  </Button>
                </div>
                <br></br>
                <Carousel responsive={responsive} style={{ gap: "20px" }}>
                  {genreEvents.map((filteredEventapi) => (
                    <div className={styles.crousgap} key={filteredEventapi._id}>
                      <EventCard
                        key={filteredEventapi._id}
                        event={filteredEventapi}
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            );
          }

          return null;
        })
      )}
    </div>
  );
};

export default Events;
