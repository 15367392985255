import styles from "../bookticket/styles.module.css";
import axios from "axios";
import {
  Breadcrumb,
  Button,
  Divider,
  Card,
  Form,
  Row,
  Col,
  Radio,
  Input,
  Spin,
} from "antd";
import Img from "../../assets/images/cov.jpg";
import logo from '../../assets/images/neloadlogo.png';
import {
  CreditCardOutlined,
  BankOutlined,
  WalletOutlined,
  GooglePayOutlined,
} from "@ant-design/icons";

const PaymentSection = ({ onNext, onPrev }) => {
  
  return (
    <div>
      <div className="pt-10 flex flex-col md:flex-row md:justify-between">
        <div>
          <p
            className=" text-[#FF275E]"
            style={{ fontSize: "22px", fontWeight: "bold" }}
          >
            Live Music by AR RAHMAN
          </p>
          <p className={styles.greyText}>
            Sardar Patel Statium, Ahmedabad{" "}
            <span className="text-[#FF275E]">|</span> 24 September 2019{" "}
            <span className="text-[#FF275E]">|</span> 7:00 pm Onwards
          </p>
          <br></br>
          <br></br>
          <br></br>
          <h2 className={styles.greyText} style={{ fontSize: "15px" }}>
            Choose your payment method
          </h2>

          <div className="flex flex-col md:flex-row gap-4 pt-4">
            <Button icon={<CreditCardOutlined />} size="large">
              Debit Card
            </Button>

            <Button icon={<BankOutlined />} size="large">
              Credit Card
            </Button>

            <Button icon={<WalletOutlined />} size="large">
              UPI
            </Button>

            <Button icon={<WalletOutlined />} size="large">
              Net Banking
            </Button>
          </div>

          <Form
            layout="vertical"
            // onFinish={onFinish}
            style={{ marginTop: "20px", maxWidth: "400px" }}
          >
            <Form.Item
              label="Name on Card"
              name="nameOnCard"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Card Number"
              name="cardNumber"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="CVV" name="cvv" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Expiry"
                  name="expiry"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item></Form.Item>
          </Form>
        </div>
        <div>
          <Card
            cover={
              <img alt="Event Cover" src={Img} style={{ height: "200px" }} />
            }
            style={{ background: "whitesmoke" }}
          >
            <p className="text-lg font-bold">Ticket Summary</p>

            <div className="pt-5">
              <div className="flex justify-between">
                <p>2X north gate</p>
                <p>₹ 2500</p>
              </div>
              <div className="flex justify-between">
                <p>2X north gate</p>
                <p>₹ 2500</p>
              </div>
              <div className="flex justify-between">
                <p>2X north gate</p>
                <p>₹ 2500</p>
              </div>
              <br></br>
              <div className="flex justify-between">
                <p>Convenience Fee</p>
                <p>₹ 25</p>
              </div>
            </div>

            <Divider />

            <div className="flex justify-between">
              <p className="font-bold text-lg ">Total</p>
              <p className="text-[#FF275E] font-bold text-lg">₹ 2500</p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button
                type="primary"
                style={{
                  background: "#FF275E",
                  padding: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
                // onClick={handlePaymentInitiation}
              >
                PAY ₹2500
              </Button>
            </div>
          </Card>
        </div>
      </div>
      {/* <Button onClick={onPrev}>Go Back</Button>
      <Button onClick={onNext}>Next</Button> */}
    </div>
  );
};

export default PaymentSection;
